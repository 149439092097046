var kb = require('knockback');
var kbDestroy = kb.ViewModel.prototype.destroy;

/* Ensure that dispose() is called as part of kb.destroy() */
kb.ViewModel.prototype.destroy = function destroy() {
    if (this.dispose) {
        this.dispose();
    }
    return kbDestroy.apply(this, arguments);
};
