var ko = require('knockout');
var $ = require('jquery');

ko.bindingHandlers.fileUpload = {
    init: function init(element, valueAccessor) {
        $(element).change(function onChange() {
            valueAccessor()(element.files[0]);
        });
    },
    update: function update(element, valueAccessor) {
        if (ko.unwrap(valueAccessor()) === null) {
            $(element)
                .wrap('<form>')
                .closest('form')
                .get(0)
                .reset();
            $(element).unwrap();
        }
    }
};
