var _ = require('lodash');
var app = require('common/app');
var remoteService = require('common/services/remote/remoteService');
var persistentSession = require('./persistentSession');

/* Creates a session for the specified user on the current realm */
function beginSession(user) {
    return remoteService
        .getUri('auth', 'beginSession', user.realmUri)
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                securityToken: user.securityToken
            });
        })
        .then(function then(res) {
            var currentUser = app.currentUser();
            var isChangeOfUser =
                !currentUser || currentUser.userId !== user.userId;

            _.extend(user, res.session);

            return app.changeUser(user, !isChangeOfUser);
        })
        .then(function then() {
            persistentSession.save(user);

            return user;
        });
}

/* Export: beginSession service */
module.exports = {
    invoke: beginSession
};
