var ko = require('knockout');

/* Applies CSS rules to document.body rather than to the target element */
ko.bindingHandlers.bodyCss = {
    update: function updateBodyCss(
        element,
        valueAccessor,
        allBindings,
        viewModel,
        bindingContext
    ) {
        return ko.bindingHandlers.css.update.call(
            this,
            document.body,
            valueAccessor,
            allBindings,
            viewModel,
            bindingContext
        );
    }
};
