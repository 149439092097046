var moment = require('moment');
var Pikaday = require('pikaday');
var dom = require('utility/dom');

require('pikaday/css/pikaday.css');
require('./datepicker-js.less');

/* Date picker implemented as a JS component */
function JsDatePicker() {
    this._input = document.createElement('input');
    this._input.type = 'text';
    this._visible = false;
}

/* Initializes the date picker in the specified parent element */
JsDatePicker.prototype._init = function init(parent, onUpdate) {
    parent.appendChild(this._input);
    dom.toggleClass(parent, 'date-picker-js', true);

    this._pika = new Pikaday({
        field: this._input,
        format: 'l',
        onSelect: onUpdate,
        onOpen: function onOpen() {
            dom.toggleClass(parent, 'picker-open', true);
        },
        onClose: function onClose() {
            if (!this._input.value) {
                this.setDate(null);
                onUpdate();
            }

            dom.toggleClass(parent, 'picker-open', false);
        }.bind(this)
    });
};

/* Returns the current value of the date picker */
JsDatePicker.prototype.getDate = function getDate() {
    var value = this._pika.getDate();
    if (value) {
        return moment(value).toDate();
    }

    return null;
};

/* Sets the value of the date picker */
JsDatePicker.prototype.setDate = function setDate(value) {
    if (value) {
        this._pika.setDate(moment(value).toDate());
    } else {
        this._pika.setDate(null);
    }
};

/* Export: JsDatePicker type */
module.exports = JsDatePicker;
