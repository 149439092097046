var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var ViewModel = require('common/viewmodel');
var registrationValidationService = require('../services/registrationValidation');
var registrationApplyService = require('../services/registrationApply');

require('./style.less');

function RegistrationApplyViewModel(userId, token) {
    var _this = this;

    _this.userId = userId;
    _this.token = token;

    _this.isValid = ko.observable(true);
    _this.loading = ko.observable(true);
    _this.registrationAttempted = ko.observable(false);
    _this.validationErrors = ko.observableArray([]);

    _this.emailAddress = ko.observable();
    _this.title = ko.observable();
    _this.password = ko.observable();
    _this.confirmPassword = ko.observable();
    _this.hasFocus = ko.observable(false);

    _this.registerOk = ko.computed(function getResetOk() {
        return _this.registrationAttempted() && _this.validationErrors().length === 0;
    });

    _this.hasValidationErrors = ko.computed(function getHasValidationErrors() {
        return _this.validationErrors().length > 0;
    });
}

_.extend(RegistrationApplyViewModel.prototype, ViewModel);

RegistrationApplyViewModel.prototype.backToLogin = function backToLogin() {
    app.navigate('auth-login');
}

RegistrationApplyViewModel.prototype.registrationApply = function registrationApply() {
    app.navigate('auth-registrationInvite');
}

RegistrationApplyViewModel.prototype.register = function register() {
    var _this = this;

    _this.loading(true);

    return registrationApplyService
        .invoke(_this.userId, _this.token, _this.password(), _this.confirmPassword())
        .then(function success(res) {
            _this.validationErrors(res.validationErrors);
            _this.registrationAttempted(true);
            return undefined;
        })
        .catch(function catchServiceErr(err) {
            _this.isValid(false);
        })
        .finally(function cleanUp() {
            _this.loading(false);
            setTimeout(function giveFocus() { _this.hasFocus(true); }, 50);
            return undefined;
        });    
}

RegistrationApplyViewModel.prototype.initialize = function initialize() {
    var _this = this;

    _this.loading(true);

    return registrationValidationService
        .invoke(_this.userId, _this.token)
        .then(function success(res) {
            _this.emailAddress(res.emailAddress);
            _this.title(res.title);
            return undefined;
        })
        .catch(function catchServiceErr(err) {
            _this.isValid(false);
        })
        .finally(function cleanUp() {
            _this.loading(false);
            setTimeout(function giveFocus() { _this.hasFocus(true); }, 50);
            return undefined;
        });
}

/* View template */
RegistrationApplyViewModel.prototype.template = require('./view.html');

/* Export: LoginViewModel type */
module.exports = RegistrationApplyViewModel;