var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var ui = require('common/ui');
var ViewModel = require('common/viewmodel');
var ServiceError = require('common/services/remote/serviceError');
var ResponseStatus = require('common/services/remote/responseStatus');
var ConfirmExitViewModel = require('common/confirmExit/viewmodel');
var runtime = require('app/runtime');
var log = require('utility/log');
var loginService = require('../services/login');

require('./style.less');

/* ViewModel used to authenticate with the application */
function LoginViewModel(forcedCompanyId) {
    var _this = this;

    // observable properties
    _this.username = ko.observable(runtime.debug.username);
    _this.password = ko.observable(runtime.debug.password);
    _this.companyId = ko.observable();
    _this.loading = ko.observable();
    _this.forcedCompanyId = forcedCompanyId;

    _this.availableCompanies = ko.observableArray();
    _this.showCompanySelect = ko.observable(false);

    // confirm exit dialog
    _this.confirmExit = new ConfirmExitViewModel();

    // attempts to log the user in to the application
    function doLogin() {
        _this.loading(true);

        loginService
            .invoke(
                _this.username(),
                _this.password(),
                _this.companyId() || _this.forcedCompanyId
            )
            .then(function then(res) {
                if (res && res.availableCompanies) {
                    if (res.availableCompanies.length) {
                        _this.availableCompanies(res.availableCompanies);
                        _this.showCompanySelect(true);
                    } else {
                        ui.notify(
                            ui.localize('Login', 'Message.NoCompaniesAvailable')
                        );
                    }

                    return undefined;
                }

                return app.navigate(runtime.config.defaultRoute);
            })
            .catch(ServiceError, function catchServiceErr(err) {
                var message;

                switch (err.status) {
                    case ResponseStatus.InvalidUser:
                        message = 'Message.InvalidUser';
                        break;

                    case ResponseStatus.PermissionError:
                        message = 'Message.PermissionError';
                        break;

                    default:
                        message = 'Message.UnknownError';
                        log.write('Service error during login', err);
                        break;
                }

                ui.notify(ui.localize('Login', message));
            })
            .catch(function catchErr(err) {
                log.write('Error performing login', err);
                if (err.statusText === 'timeout') {
                    ui.notify(ui.localize('Generic', 'Message.RemoteTimeout'));
                } else {
                    ui.notify(ui.localize('Login', 'Message.UnknownError'));
                }
            })
            .finally(function final() {
                _this.loading(false);
            });
    }

    // attempts to log the user in with a username and password only
    _this.login = function login() {
        if (!_this.username() || !_this.password()) {
            ui.notify(ui.localize('Login', 'Message.RequiredFields'));
            return;
        }

        doLogin();
    };

    // attempts to log the user in after selecting a company
    _this.loginWithCompany = function loginWithCompany() {
        if (!_this.companyId()) {
            ui.notify(ui.localize('Login', 'Message.RequiredFieldsCompany'));
            return;
        }

        doLogin();
    };

    // navigates the user to the password reset page
    _this.passwordReset = function passwordReset() {
        app.navigate('auth-passwordResetInvite');
    }

    // navigates the user to the register page
    _this.register = function register() {
        app.navigate('auth-registrationInvite');
    }

    // reset the login form to its initial state
    _this.reset = function reset() {
        _this.username(runtime.debug.username || '');
        _this.password(runtime.debug.password || '');
        _this.companyId('');
        _this.availableCompanies([]);
        _this.showCompanySelect(false);
    };
}

// add ViewModel mixins
_.extend(LoginViewModel.prototype, ViewModel);

/* View template */
LoginViewModel.prototype.template = require('./view.html');

/* Export: LoginViewModel type */
module.exports = LoginViewModel;
