var _ = require('lodash');
var Promise = require('promise');
var app = require('common/app');
var ui = require('common/ui');
var remoteService = require('common/services/remote/remoteService');
var ServiceError = require('common/services/remote/serviceError');
var ResponseStatus = require('common/services/remote/responseStatus');
var log = require('utility/log');
var beginSession = require('./beginSession');

/* Invokes a remote service as the current authenticated user */
function authenticatedService(packageUri, operation, data) {
    var user;
    var serviceData;
    var serviceTask;

    // trap promise for cancellation
    serviceTask = Promise.resolve(true)
        .then(function then() {
            user = app.currentUser();

            if (!user) {
                throw new Error('User is not authenticated');
            }

            return remoteService.getUri(packageUri, operation, user.realmUri);
        })
        .then(function then(serviceUri) {
            /* Notify the user that their credentials have expired and close the session */
            function notAuthenticated(task) {
                // session expired, notify the user and redirect to login
                log.write('User session not available, logging user out');
                ui.notify(
                    ui.localize('Auth', 'Message.SessionExpired'),
                    function onDismiss() {
                        return app.navigate('auth-logout');
                    }
                );

                // cancel the promise chain to prevent further operations while we're leaving the session
                task.cancel();
                return undefined;
            }

            /* Attempt to invoke the service, handling an InvalidUser response */
            function tryInvoke(withRetry) {
                // authenticated requests require a session token
                serviceData = _.merge(
                    {
                        sessionToken: user.sessionToken
                    },
                    data || {}
                );

                // invoke the service
                return remoteService
                    .invoke(serviceUri, serviceData)
                    .catch(ServiceError, function catchErr(err) {
                        var retryTask;

                        // check for an InvalidUser response
                        if (err.status === ResponseStatus.InvalidUser) {
                            // if retry is enabled, attempt to begin a new session and re-send the request
                            if (withRetry && user.securityToken) {
                                log.write(
                                    'User session has ended, attempting to re-authenticate'
                                );

                                // trap promise for cancellation
                                retryTask = beginSession
                                    .invoke(user)
                                    .catch(function failed() {
                                        return notAuthenticated(retryTask);
                                    })
                                    .then(function retry() {
                                        return tryInvoke(false);
                                    });

                                return retryTask;
                            }

                            return notAuthenticated(serviceTask);
                        }

                        // we're only interesting in handling InvalidUser errors
                        throw err;
                    });
            }

            // begin invocation attempt
            return tryInvoke(true);
        });

    return serviceTask;
}

/* Export: authenticatedService library */
module.exports = {
    invoke: authenticatedService
};
