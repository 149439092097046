var app = require('common/app');
var localization = require('common/localization');
var HeaderViewModel = require('common/header/viewmodel');
var BackButtonViewModel = require('common/backButton/viewmodel');
var MenuViewModel = require('./menu/viewmodel');
var restoreSession = require('./auth/services/restoreSession');
var util = require('utility/util');

require('./style/main.less');

localization.registerPhraseLibraryByKeys(require('./generic/phrases'));

if (__DEBUG__) {
    require('../auditClient/debug/devTools');
}

module.exports = function main(onLoaded) {
    var securityToken = util.getQueryStringParameterByName('securityToken');
    var action = util.getQueryStringParameterByName('action');
    var primaryKey = util.getQueryStringParameterByName('primaryKey');
    var userId = util.getQueryStringParameterByName('i');
    var token = util.getQueryStringParameterByName('t');
    var route = util.getQueryStringParameterByName('r');

    app.create('app', {
        header: new HeaderViewModel(),
        menu: new MenuViewModel()
    });
    app.loadPackage(require('./auth/package'));
    app.loadPackage(require('./home/package'));
    app.header.add(new BackButtonViewModel(app));
    app.menu.init();
    app.module = 'portal';

    restoreSession.invoke().finally(function final() {
        if (securityToken) {
            app.start('auth-passThru', [securityToken, action, primaryKey]);
        } else if (route === 'p' && userId && token) {
            app.start('auth-passwordResetApply', [userId, token]);
        } else if (route === 'r' && userId && token) {
            app.start('auth-registrationApply', [userId, token]);
        } else {
            app.start('auth-login');
        }

        if (onLoaded) {
            onLoaded(app);
        }
    });
};
