var Modernizr = require('modernizr');
var dom = require('utility/dom');
var DatePicker;

require('./datepicker.less');

/* We look for the 'date' input type and touch support to infer the presence of a mobile device */
function useNativePicker() {
    return Modernizr.inputtypes.date && Modernizr.touchevents;
}

// Select base picker implementation
if (useNativePicker()) {
    DatePicker = require('./datepicker-native');
} else {
    DatePicker = require('./datePicker-js');
}

/* Initializes the date picker in the specified parent element */
DatePicker.prototype.init = function init(parent) {
    var toggle = document.createElement('a');
    toggle.href = '#';

    dom.toggleClass(parent, 'date-picker', true);
    parent.appendChild(toggle);

    this._init.apply(this, arguments);
};

/* Export: DatePicker type */
module.exports = DatePicker;
