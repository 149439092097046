var Action = require('common/actions/action');
var app = require('../app');
var runtime = require('app/runtime');

/* Base type for an Action which the user must be authenticated to access */
function AuthenticatedAction() {
    // no constructor
}

/* Action inheritance */
AuthenticatedAction.prototype = new Action();

/* Action can only be activated if the user is authenticated; otherwise, redirect to the login screen */
AuthenticatedAction.prototype.allowActivate = function allowActivate() {
    return app.currentUser() ? true : runtime.config.loginRoute;
};

/* Export: Action type*/
module.exports = AuthenticatedAction;
