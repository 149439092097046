var _ = require('lodash');
var Promise = require('promise');
var stringify = require('json-stable-stringify');
var settings = require('common/settings');
var runtime = require('app/runtime');
var AjaxError = require('./ajaxError');
var ServiceError = require('./serviceError');
var ResponseStatus = require('./responseStatus');

var msTypeHint = '__type';
var debugTimeout = 1250;

/* Stable Json sort which prioritises the MS Type Hint property */
function jsonCompare(a, b) {
    if (a.key === msTypeHint) {
        return -1;
    }

    if (b.key === msTypeHint) {
        return 1;
    }

    return a.key < b.key ? -1 : 1;
}

/* Wraps an Ajax call in a trusted Promise */
function ajax(method, url, data) {
    var requestOptions = {
        method: method.toUpperCase(),
        cache: 'no-cache',
        headers: {},
        credentials: 'include'
    };
    var request;

    if (data && requestOptions.method !== 'GET') {
        requestOptions.body = stringify(data, jsonCompare);
        requestOptions.headers['content-type'] = 'application/json';
    }

    request = fetch(url, requestOptions);

    return Promise.resolve(request)
        .then(function parseResponse(res) {
            if (!res.ok) {
                throw new AjaxError(res);
            }

            return res.json();
        })
        .then(function handleResponse(res) {
            if (
                !_.isUndefined(res.Status) &&
                res.Status !== ResponseStatus.Ok
            ) {
                throw new ServiceError(res);
            }

            if (__DEBUG__) {
                return new Promise(function promise(resolve) {
                    setTimeout(function timeout() {
                        resolve(res);
                    }, debugTimeout);
                });
                // eslint-disable-next-line no-else-return
            } else {
                return res;
            }
        });
}

/* Returns the resolved host */
function getResolvedHost(host) {
    return host
        ? Promise.resolve(runtime.debug.serviceHost || host)
        : settings.get('remoteHost');
}

/* Returns the full uri for a remote service operation */
function getUri(service, operation, host) {
    var serviceUri = runtime.config.remoteServicePath.replace(
        '{service}',
        service
    );
    return getResolvedHost(host).then(function then(resolvedHost) {
        return resolvedHost + '/' + serviceUri + '/' + operation;
    });
}

/* Invokes a service as a POST operation */
function invoke(serviceUri, data) {
    return ajax('POST', serviceUri, { request: data });
}

/* Exports: remote service utilities */
module.exports = {
    getUri: getUri,
    getResolvedHost: getResolvedHost,
    invoke: invoke
};
