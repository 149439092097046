var _ = require('lodash');
var ko = require('knockout');
var Backbone = require('backbone');
var app = require('common/app');
var ViewModel = require('common/viewmodel');
var gc = require('utility/gc');

/* ViewModel used to load an array of components from the active package list */
function PackageWatchingViewModel(componentFactory) {
    if (!componentFactory) {
        return;
    }

    this._knownComponents = {};
    this._components = ko.observableArray().extend({ rateLimit: 25 });
    this._componentFactory = componentFactory;

    this.listenTo(app, 'change:packages', this._refreshComponents.bind(this));
    this.listenTo(app, 'change:user', this._resetComponents.bind(this));
    this._refreshComponents();
}

// add ViewModel mixins
_.extend(PackageWatchingViewModel.prototype, ViewModel);

// add Event mixins
_.extend(PackageWatchingViewModel.prototype, Backbone.Events);

/* Loads a list of components exposed by the current package list */
PackageWatchingViewModel.prototype._refreshComponents = function refreshComponents() {
    var components = this._components().slice();

    _.each(
        app.activePackages(),
        function each(p) {
            var component;
            if (this._knownComponents[p.id]) {
                return;
            }

            this._knownComponents[p.id] = true;

            if (typeof p[this._componentFactory] !== 'function') {
                return;
            }

            component = p[this._componentFactory]();

            if (_.isArray(component)) {
                components = components.concat(component);
            } else {
                components.push(component);
            }
        }.bind(this)
    );

    components = _.sortBy(components, function sortBy(component) {
        return component.priority || 0;
    });

    this._components(components);

    return null;
};

/* Reloads the list of components from scratch */
PackageWatchingViewModel.prototype._resetComponents = function resetComponents() {
    this._releaseComponents();
    this._knownComponents = {};

    return this._refreshComponents();
};

/* Releases all registered components */
PackageWatchingViewModel.prototype._releaseComponents = function releaseComponents() {
    var components = this._components().slice();
    _.each(components, gc.release);

    this._components.removeAll();
};

/* Cleans up memory allocations when disposing of the PackageWatchingViewModel */
PackageWatchingViewModel.prototype.dispose = function dispose() {
    this.stopListening();
    this._releaseComponents();
    gc.releaseKeys(this);
};

/* Export: PackageWatchingViewModel type */
module.exports = PackageWatchingViewModel;
