var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var ui = require('common/ui');
var ViewModel = require('common/viewmodel');
var RegistrationInviteService = require('../services/registrationInvite');

require('./style.less');

function RegistrationInviteViewModel() {
    var _this = this;
    _this.loading = ko.observable();

    _this.email = ko.observable();
    _this.complete = ko.observable(false);
    _this.error = ko.observable(false);

    function submitInvite() {
        _this.loading(true);
        RegistrationInviteService.invoke(_this.email())
            .then(function then() {
                _this.complete(true);
                return undefined;
            })
            .catch(function catchErr(err) {
                _this.loading(false);
                _this.error(true);
            })
            .finally(function final() {
                _this.loading(false);
            });
    }

    _this.submit = function submit() {
        // validation
        if (!_this.email()) {
            ui.notify(
                ui.localize('RegistrationInvite', 'Message.RequiredField')
            );
            return;
        }
        submitInvite();
    };

    _this.back = function back() {
        app.navigate('auth-login');
    };
}

// add ViewModel mixins
_.extend(RegistrationInviteViewModel.prototype, ViewModel);

RegistrationInviteViewModel.prototype.backToLogin = function backToLogin() {
    app.navigate('auth-login');
};

/* View template */
RegistrationInviteViewModel.prototype.template = require('./view.html');

/* Export: RegistrationInviteViewModel type */
module.exports = RegistrationInviteViewModel;
