var Promise = require('promise');

/* Base type for an Action */
function Action() {
    // no constructor
}

/* Handle navigation to this Action */
Action.prototype.handle = function handle() {
    return false;
};

/* Returns the route which will display the current state of this Action */
Action.prototype.getRoute = function getRoute() {
    throw new Error('Action does not implement getRoute()');
};

/* Initializes the action in preparation for activation */
Action.prototype.initialize = function initialize() {
    return Promise.resolve(true);
};

/* Renders and returns the View for this Action */
Action.prototype.view = function view() {
    if (!this.viewModel) {
        throw new Error('Action does not expose a viewModel');
    }
    return this._view || (this._view = this.viewModel.render());
};

/* Indicates if the action can be activated at this time */
Action.prototype.allowActivate = function allowActivate() {
    return true;
};

/* Describes the location the user should be returned to upon leaving this action via the 'back' button */
Action.prototype.getBackLocation = function getBackLocation() {
    return false;
};

/* Export: Action type*/
module.exports = Action;
