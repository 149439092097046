/* Create an object of a known type and invokes the constructor */
function createObject(type, args) {
    var instance = Object.create(type.prototype);
    return type.apply(instance, args) || instance;
}

/* Indicates if a value is a number */
function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/* Escapes a string so that it can be used safely in a regular expression */
function regexEscape(value) {
    // eslint-disable-next-line no-useless-escape
    return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

/**
 * Reference: https://www.arungudelli.com/tutorial/javascript/get-query-string-parameter-values-from-url-using-javascript/
 * This function getQueryStringParameterByName takes parameter name and url
 * as parmaters and returns parameter value
 * @parameter {String} parameterName
 * @parameter {String} url
 * (if url is not passed it takes the current url from window.location.href)
 **/

function getQueryStringParameterByName(parameterName, href) {
    var regularExpression;
    var results;
    var name = parameterName;
    var url = href;

    if (!url) url = window.location.href;
    name = parameterName.replace(/[[\]]/g, '\\$&');
    regularExpression = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    results = regularExpression.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function removeQueryStringParameters() {
    var hash;
    var cleanUri = location.protocol + '//' + location.host + location.pathname;
    var hashPos = location.href.indexOf('#');
    if (hashPos > 0) {
        hash = location.href.substring(hashPos, location.href.length);
        cleanUri += hash;
    }
    window.history.replaceState({}, document.title, cleanUri);
}

function removeQueryStringParameter(key, value) {
    var baseUrl = [
        location.protocol,
        '//',
        location.host,
        location.pathname
    ].join('');
    var urlQueryString = document.location.search;
    var newParam = key + '=' + value;
    var params = '?' + newParam;
    var updateRegex;
    var removeRegex;

    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        updateRegex = new RegExp('([?&])' + key + '[^&]*');
        removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');

        if (typeof value === 'undefined' || value === null || value === '') {
            // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, '$1');
            params = params.replace(/[&;]$/, '');
        } else if (urlQueryString.match(updateRegex) !== null) {
            // If param exists already, update it
            params = urlQueryString.replace(updateRegex, '$1' + newParam);
        } else {
            // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
        }
    }
    window.history.replaceState({}, '', baseUrl + params);
}

/* Export: utility namespace */
module.exports = {
    createObject: createObject,
    isNumeric: isNumeric,
    regexEscape: regexEscape,
    getQueryStringParameterByName: getQueryStringParameterByName,
    removeQueryStringParameters: removeQueryStringParameters,
    removeQueryStringParameter: removeQueryStringParameter
};
