var authenticatedService = require('../../auth/services/authenticatedService');

/* Retrieves the form response associated with the supplied form request */
function getFormResponse(formRequestId) {
    return authenticatedService.invoke('forms', 'getFormResponse', {
        formRequestId: formRequestId
    });
}

/* Export: getFormResponse service */
module.exports = {
    invoke: getFormResponse
};
