var Promise = require('promise');
var app = require('common/app');
var Action = require('common/actions/action');
var RegistrationInviteViewModel = require('./viewmodel');

/* Action used to request Registration invites */
function RegistrationInviteAction() {
}

/* Action inheritance */
RegistrationInviteAction.prototype = new Action();

/* Single pane style control */
RegistrationInviteAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
RegistrationInviteAction.route = 'auth/RegistrationInvite';

/* Returns a route containing the specified parameters */
RegistrationInviteAction.getRoute = function getRoute() {
    return RegistrationInviteAction.route;
};

/* Returns the route which will display the current state of this Action */
RegistrationInviteAction.prototype.getRoute = function getRoute() {
    return RegistrationInviteAction.getRoute();
};

/* Login can only be activated if the user is not authenticated */
RegistrationInviteAction.prototype.allowActivate = function allowActivate() {
    if (app.currentUser()) {
        return 'dashboard';
    }
    return true;
};

/* Initializes the action in preparation for activation */
RegistrationInviteAction.prototype.initialize = function initialize() {
    this.viewModel = new RegistrationInviteViewModel();
    return Promise.resolve(true);
};

/* Export: RegistrationInviteAction type */
module.exports = RegistrationInviteAction;
