/* ViewModel to display a collection of enrolled checklist services for an entity */
function DashboardGroupViewModel(group, components, loadingObs) {
    this.title = group.title;
    this.components = components;
}

/* View template */
DashboardGroupViewModel.prototype.template = require('./view.html');

/* Export; DashboardGroupViewModel type */
module.exports = DashboardGroupViewModel;
