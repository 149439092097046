var ko = require('knockout');

/* Triggers a handler function when the Enter key is pressed within the bound element */
ko.bindingHandlers.defaultAction = {
    init: function init(
        element,
        valueAccessor,
        allBindings,
        viewModel,
        bindingContext
    ) {
        ko.utils.registerEventHandler(element, 'keypress', function onKeyPress(
            event
        ) {
            var handlerFunction;
            var argsForHandler;
            var localViewModel;
            var handlerReturnValue;

            // only fire if the keypress is the Enter key
            if (event.keyCode !== 13) {
                return;
            }

            // identify handler function
            handlerFunction = valueAccessor();
            if (!handlerFunction) {
                return;
            }

            // raise the 'change' event on the target to ensure any bindings are updated
            if (event.target) {
                ko.utils.triggerEvent(event.target, 'change');
            }

            // invoke handler using the event arguments
            try {
                argsForHandler = ko.utils.makeArray(arguments);
                localViewModel = bindingContext.$data;
                argsForHandler.unshift(localViewModel);
                handlerReturnValue = handlerFunction.apply(
                    localViewModel,
                    argsForHandler
                );
            } finally {
                // prevent default action, unless the handler has returned true
                if (handlerReturnValue !== true) {
                    if (event.preventDefault) {
                        event.preventDefault();
                    } else {
                        event.returnValue = false;
                    }
                }
            }
        });
    }
};
