/* Array of items which can be disposed */
function DisposableArray() {
    // no constructor
}

/* DisposableArray extends Array */
DisposableArray.prototype = [];

/* Disposing the array disposes all items in it */
DisposableArray.prototype.dispose = function dispose() {
    var item;
    while (this.length) {
        item = this.shift();
        if (item.dispose && typeof item.dispose === 'function') {
            item.dispose();
        }
    }
};

/* Exports: DisposableArray type */
module.exports = DisposableArray;
