/* Toggles the state of a css class against the element */
function toggleClass(element, className, isActive) {
    var classes;
    var idx;

    // prefer element.classList if available
    if (element.classList) {
        if (element.classList.contains(className)) {
            if (!isActive) {
                element.classList.remove(className);
            }
        } else if (isActive) {
            element.classList.add(className);
        }
    } else {
        // split the className and re-assemble as required
        classes = element.className.split(' ');
        idx = classes.indexOf(className);

        if (idx >= 0) {
            if (!isActive) {
                classes.splice(idx, 1);
            }
        } else if (isActive) {
            classes.push(className);
        }

        element.className = classes.join(' ');
    }
}

/* Encodes a string for display as part of generated HTML, escaping any unsafe values */
function htmlEncode(value) {
    var element = document.createElement('span');
    element.innerText = value;
    return element.innerHTML || '';
}

/* Export: DOM manipulation library */
module.exports = {
    toggleClass: toggleClass,
    htmlEncode: htmlEncode
};
