var remoteService = require('common/services/remote/remoteService');

/* Requests a password reset email to be sent to a user account email */
function passwordResetInvite(username) {
    return remoteService
        .getUri('auth', 'passwordResetInvite')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                username: username
            });
        })
        .then(function then(res) {
            return res;
        })
}

/* Export: passwordResetInvite service */
module.exports = {
    invoke: passwordResetInvite
};
