var Promise = require('promise');
var AuthenticatedAction = require('common/actions/authenticatedAction');
var DashboardViewModel = require('./viewmodel');

/* Action which displays the user's dashboard */
function DashboardAction() {
    // no constructor
}

/* AuthenticatedAction inheritance */
DashboardAction.prototype = new AuthenticatedAction();

/* Route pattern which is handled by this Action */
DashboardAction.route = 'dashboard';

/* Returns a route containing the specified parameters */
DashboardAction.getRoute = function getRoute() {
    return DashboardAction.route;
};

/* Returns the route which will display the current state of this Action */
DashboardAction.prototype.getRoute = function getRoute() {
    return DashboardAction.getRoute();
};

/* Allow the app to exit from this action */
DashboardAction.prototype.onBackRequested = function onBackRequested() {
    this.viewModel.confirmExit.notify();
};

/* Initializes the action in preparation for activation */
DashboardAction.prototype.initialize = function initialize() {
    this.viewModel = new DashboardViewModel();
    return Promise.resolve(true);
};

/* Export: DashboardAction type */
module.exports = DashboardAction;
