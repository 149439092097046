var cookie = require('cookie-monster');
var runtime = require('app/runtime');

/* Sets the value of a cookie */
function set(key, value) {
    const cookieDomain = runtime.debug.serviceHost
        ? 'localhost'
        : '.audit-hub.com';
    cookie.setItem(key, value, { secure: true, domain: cookieDomain });
}

/* Gets the value of a cookie */
function get(key) {
    return cookie.getItem(key);
}

/* Clears a cookie */
function remove(key) {
    cookie.removeItem(key);
}

/* Exports: cookie operations library */
module.exports = {
    set: set,
    get: get,
    remove: remove
};
