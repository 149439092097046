var ko = require('knockout');
var ui = require('common/ui');

var originalApplyBindings;

originalApplyBindings = ko.applyBindings;

ko.applyBindings = function applyBindings(vm, node, extendCallback) {
    return originalApplyBindings(vm, node || document.body, function callback(
        context
    ) {
        if (extendCallback) {
            extendCallback(context);
        }

        context['$localize'] = ui.localize;
    });
};
