var app = require('common/app');
var ko = require('knockout');
var ViewModel = require('common/viewmodel');
var runtime = require('app/runtime');
var ServiceError = require('common/services/remote/serviceError');
var ui = require('common/ui');
var ResponseStatus = require('common/services/remote/responseStatus');
var log = require('utility/log');
var passThruService = require('../services/passThru');
var beginSession = require('../services/beginSession');
var getFormResponseService = require('../../forms/services/getFormResponse');
var _ = require('lodash');

require('./style.less');

function PassThruViewModel(securityToken, action, primaryKey) {
    var _this = this;

    _this.securityToken = securityToken;
    _this.action = action;
    _this.primaryKey = primaryKey;
    _this.loading = ko.observable(true);
}

// add ViewModel mixins
_.extend(PassThruViewModel.prototype, ViewModel);

PassThruViewModel.prototype.initialize = function initialize() {
    var _this = this;

    _this.loading(true);

    return passThruService
        .invoke(_this.securityToken)
        .then(function success(res) {
            return beginSession.invoke(res);
        })
        .then(function getResponse() {
            return getFormResponseService.invoke(_this.primaryKey);
        })
        .then(function navigate(response) {
            return app.navigate(
                _this.action || runtime.config.defaultRoute,
                [response.formResponseId, 1],
                { replace: true }
            );
        })
        .catch(ServiceError, function catchServiceErr(err) {
            var message;
            var action = 'auth-login';

            switch (err.status) {
                case ResponseStatus.InvalidUser:
                    message = 'Message.InvalidUser';
                    break;

                case ResponseStatus.PermissionError:
                    message = 'Message.PermissionError';
                    break;

                default:
                    message = 'Message.InvalidFormRequest';
                    action = 'home-dashboard';
                    log.write(
                        'Service error during pass thru for response: ' +
                            _this.primaryKey,
                        err
                    );
                    break;
            }

            ui.notify(ui.localize('Login', message));

            return app.navigate(action);
        })
        .catch(function catchErr(err) {
            log.write('Error performing login', err);
            if (err.statusText === 'timeout') {
                ui.notify(ui.localize('Generic', 'Message.RemoteTimeout'));
            } else {
                ui.notify(ui.localize('Login', 'Message.UnknownError'));
            }

            return app.navigate('auth-login');
        })
        .finally(function cleanUp() {
            _this.loading(false);
            return true;
        });
};

/* View template */
PassThruViewModel.prototype.template = require('./view.html');

/* Export: PassThruViewModel type */
module.exports = PassThruViewModel;
