var remoteService = require('common/services/remote/remoteService');

/* Validates a password reset request */
function passwordResetValidation(userId, token) {
    return remoteService
        .getUri('auth', 'passwordResetValidation')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                userId: userId,
                token: token
            });
        });
}

/* Export: login service */
module.exports = {
    invoke: passwordResetValidation
};