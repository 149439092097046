var _ = require('lodash');

// internal storage for localizations
var data = {};

/* Registers a library of phrases against the specified class key */
function registerPhraseLibrary(classKey, phrases) {
    data[classKey] = phrases || {};
}

/* Registers a library of phrases against the specified class key */
function registerPhraseLibraryByKeys(library) {
    _.forIn(library, function forIn(value, key) {
        registerPhraseLibrary(key, value);
    });
}

/* Looks up a phrase by class and resource key */
function getPhrase(classKey, resourceKey) {
    return (data[classKey] || {})[resourceKey] || resourceKey;
}

/* Export: localization library */
module.exports = {
    registerPhraseLibrary: registerPhraseLibrary,
    registerPhraseLibraryByKeys: registerPhraseLibraryByKeys,
    getPhrase: getPhrase
};
