/* Error type indicating that an Ajax request has failed */
function AjaxError(response) {
    Error.call(this, response.statusText);
    this.status = response.status;
    this.statusText = response.statusText;
    this.response = response;
}

/* AjaxError inherits Error */
AjaxError.prototype = Object.create(Error.prototype);
AjaxError.prototype.constructor = Error;

/* Export: AjaxError type */
module.exports = AjaxError;
