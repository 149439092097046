// Backbone 1.1.2 was built using Underscore, which is directly compatible with Lodash 3.x
// However, we now use Lodash 4.x, which has renamed or removed a number of methods who's behavior
// is covered by other parts of the Lodash library.
// Fortunately, the required methods are still provided by the Lodash library, just under
// a different name, so we can add aliases for the methods which aren't provided.
// This is possible because Lodash provides overloads for these functions which match the signatures
// of the removed methods.
// This should be safer than updating Backbone (and also Knockback) through several major revisions,
// which could impact behavior which we rely on in other parts of the app.

module.exports = {
    any: 'some',
    all: 'every',
    findWhere: 'find',
    pluck: 'map',
    where: 'filter'
};
