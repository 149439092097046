'use strict';

// Modernizr imports itself to the global namespace.
// This polyfill captures the library and wraps it for use with webpack/CommonJS

var Modernizr = window.Modernizr;
delete window.Modernizr;

/* Export: Modernizr instance */
module.exports = Modernizr;
