var ko = require('knockout');
var gainFocusTimeout = 100;

ko.bindingHandlers.gainFocus = {
    update: function updateFocus(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());

        if (value) {
            setTimeout(function gainFocus() {
                element.focus();
            }, gainFocusTimeout);
        }
    }
};
