var _ = require('lodash');
var ko = require('knockout');
var ui = require('common/ui');
var log = require('utility/log');
var DisposableArray = require('utility/disposableArray');

/* Export: viewmodel method mixins */
module.exports = {
    /* Renders the viewmodel's view template and binds it against the viewmodel */
    render: function render() {
        var template = _.result(this, 'template');
        var element;

        if (!template) {
            throw new Error('render: template is undefined');
        }

        element = document.createElement('div');
        element.innerHTML = template;
        if (element.children.length === 1) {
            element = element.children[0];
        }

        ko.applyBindings(this, element);

        return element;
    },

    /* Tracks an item to be disposed when the viewmodel goes out of scope */
    trackDisposable: function trackDisposable(disposable) {
        if (!this._disposable) {
            this._disposable = new DisposableArray();
        }

        this._disposable.push(disposable);
    },

    /* Catches an error and logs it to the console with the specified message */
    catch: function createCatch(message, uiMessage, callback) {
        var uiMessageOrDefault = uiMessage || [
            'Generic',
            'Message.UnknownError'
        ];
        return function catchError(err) {
            var onDismiss;
            if (callback) {
                onDismiss = function onDismissErrorNotification() {
                    return callback(err);
                };
            }

            log.write(message, err);

            ui.notify(ui.localize.apply(ui, uiMessageOrDefault), onDismiss);
        };
    }
};
