/* Export: ResponseStatus enumeration */
module.exports = {
    Ok: 0,
    InvalidRequest: 1,
    InvalidUser: 2,
    PermissionError: 3,
    ServerError: 4,
    UnknownError: 5,
    Failed: 6
};
