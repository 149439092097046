var _ = require('lodash');
var ui = require('common/ui');
var app = require('common/app');

/* ViewModel used to display an item in the application menu */
function MenuItemViewModel(data, menu) {
    this.label = _.isArray(data.label)
        ? ui.localize.apply(ui, data.label)
        : data.label;
    this.itemClass = data.class;
    this.action = data.action;

    this.onActivate = function onActivate() {
        menu.isOpen(false);
    };
}

/* View template */
MenuItemViewModel.prototype.template = require('./view.html');

/* Activate the menu item by navigating to the desired action */
MenuItemViewModel.prototype.activate = function activate() {
    this.onActivate();
    return app.navigate(this.action);
};

/* Export: MenuItemViewModel type */
module.exports = MenuItemViewModel;
