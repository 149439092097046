var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var PackageWatchingViewModel = require('../generic/packageWatchingViewModel');
var MenuItemViewModel = require('./menuItem/viewmodel');
var MenuToggleViewModel = require('./menuToggle/viewmodel');

require('./style.less');

/* ViewModel used to display the application menu */
function MenuViewModel() {
    PackageWatchingViewModel.call(this, 'menu');

    this.isOpen = ko.observable(false);
    this.toggle = new MenuToggleViewModel(this);

    this.items = ko.computed(
        function computed() {
            return _.map(
                this._components(),
                function map(component) {
                    return new MenuItemViewModel(component, this);
                }.bind(this)
            );
        }.bind(this)
    );
}

/* MenuViewModel extends PackageWatchingViewModel */
MenuViewModel.prototype = new PackageWatchingViewModel();

/* View template */
MenuViewModel.prototype.template = require('./view.html');

/* Initialize the menu viewmodel */
MenuViewModel.prototype.init = function init() {
    app.header.add(this.toggle);
};

/* Export: MenuViewModel type */
module.exports = MenuViewModel;
