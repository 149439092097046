var ko = require('knockout');
var dom = require('utility/dom');

/* Update the size of the splash element based on the loaded image */
function updateSize(e) {
    var img = e.target || e.srcElement;
    var element;
    var container;
    var naturalHeight;
    var naturalWidth;
    var containerHeight;
    var containerWidth;
    var scaleX;
    var scaleY;
    var scale;
    var targetWidth;
    var targetHeight;

    if (!img || !img.currentSrc) {
        return;
    }

    element = img._element;
    container = element.parentNode;
    naturalHeight = img.naturalHeight;
    naturalWidth = img.naturalWidth;
    containerHeight = container.offsetHeight;
    containerWidth = container.offsetWidth;

    if (
        !naturalHeight ||
        !naturalWidth ||
        !containerHeight ||
        !containerWidth
    ) {
        return;
    }

    scaleX = containerWidth / naturalWidth;
    scaleY = containerHeight / naturalHeight;
    scale = Math.min(Math.min(scaleX, scaleY), 1);

    targetWidth = Math.floor(naturalWidth * scale + 0.5);
    targetHeight = Math.floor(naturalHeight * scale + 0.5);

    element.style.width = targetWidth + 'px';
    element.style.height = targetHeight + 'px';
    element.style.backgroundImage = img.currentSrc
        ? "url('" + img.currentSrc + "')"
        : '';
    dom.toggleClass(element, 'loading', false);
}

/* Sets the background image of an element to the specified url */
ko.bindingHandlers.splashBackground = {
    init: function init(element) {
        element._splashImage = new Image();
        element._splashImage.onload = updateSize;
        element._splashImage._element = element;

        function onResize() {
            updateSize({ target: element._splashImage });
        }

        window.addEventListener('resize', onResize);

        ko.utils.domNodeDisposal.addDisposeCallback(
            element,
            function onDispose() {
                window.removeEventListener('resize', onResize);
            }
        );
    },
    update: function update(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());

        dom.toggleClass(element, 'loading', true);

        if (value) {
            element._splashImage.src = value;
        } else {
            element._splashImage.src = null;
            element.style.backgroundImage = '';
        }
    }
};
