var Promise = require('promise');

/* Error type indicating that a service operation returned a non-OK status code */
function ServiceError(response) {
    Promise.OperationalError.call(this, response.Message || 'Unknown Error');
    this.status = response.Status;
    this.response = response;
}

/* ServiceError inherits OperationalError */
ServiceError.prototype = Object.create(Promise.OperationalError.prototype);
ServiceError.prototype.constructor = ServiceError;

/* Export: ServiceError type */
module.exports = ServiceError;
