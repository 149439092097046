var moment = require('moment');
var dom = require('utility/dom');

require('./datepicker-native.less');

/* Date picker using the HTML 5 'date' input type */
function NativeDatePicker() {
    this._input = document.createElement('input');
    this._input.type = 'date';
    dom.toggleClass(this._input, 'picker-input', true);

    this._display = document.createElement('input');
    this._display.type = 'text';
    this._display.readonly = 'readonly';
    dom.toggleClass(this._display, 'picker-display', true);

    this._updateDisplay();
}

/* moment.js date format for reading and writing to the internal data field */
NativeDatePicker.prototype._dateFormat = 'YYYY-MM-DD';

/* Initializes the date picker in the specified parent element */
NativeDatePicker.prototype._init = function init(parent, onUpdate) {
    parent.appendChild(this._input);
    parent.appendChild(this._display);

    this._input.addEventListener(
        'change',
        function onChange() {
            this._updateDisplay();
            onUpdate();
        }.bind(this)
    );
};

/* Internal helper to get the current value as a moment */
NativeDatePicker.prototype._getValue = function getValue() {
    var value = this._input.value;
    if (value) {
        return moment(value, this._dateFormat);
    }

    return null;
};

/* Updates the displayed value in the picker */
NativeDatePicker.prototype._updateDisplay = function updateDisplay() {
    var value = this._getValue();
    if (value) {
        this._display.value = value.format('l');
    } else {
        this._display.value = null;
    }
};

/* Returns the current value of the date picker */
NativeDatePicker.prototype.getDate = function getDate() {
    var value = this._getValue();
    if (value) {
        return value.toDate();
    }

    return null;
};

/* Sets the value of the date picker */
NativeDatePicker.prototype.setDate = function setDate(value) {
    if (value) {
        this._input.value = moment(value).format(this._dateFormat);
    } else {
        this._input.value = null;
    }
    this._updateDisplay();
};

/* Export: NativeDatePicker type */
module.exports = NativeDatePicker;
