var Promise = require('promise');
var app = require('common/app');
var Action = require('common/actions/action');
var persistentSession = require('../services/persistentSession');
var LogoutViewModel = require('./viewmodel');

/* Action used to log out of the application */
function LogoutAction() {
    // no constructor
}

/* Action inheritance */
LogoutAction.prototype = new Action();

/* Single pane style control */
LogoutAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
LogoutAction.route = 'auth/logout';

/* Returns a route containing the specified parameters */
LogoutAction.getRoute = function getRoute() {
    return LogoutAction.route;
};

/* Returns the route which will display the current state of this Action */
LogoutAction.prototype.getRoute = function getRoute() {
    return LogoutAction.getRoute();
};

/* Initializes the action in preparation for activation */
LogoutAction.prototype.initialize = function initialize() {
    this.viewModel = new LogoutViewModel();
    return Promise.resolve(true);
};

/* Log the user out upon activation and trigger a navigation back to the login screen */
LogoutAction.prototype.onActivate = function onActivate() {
    app.changeUser(null);
    persistentSession.save(null);
    return app.navigate('auth-login', []);
};

/* Export: LogoutAction type */
module.exports = LogoutAction;
