var remoteService = require('common/services/remote/remoteService');

/* Requests a registeration email to be sent to a user account email */
function registration(username) {
    return remoteService
        .getUri('auth', 'registrationInvite')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                username: username
            });
        })
        .then(function then(res) {
            return res;
        })
}

/* Export: registration service */
module.exports = {
    invoke: registration
};
