var _ = require('lodash');
var Backbone = require('backbone');
var util = require('utility/util');
var log = require('utility/log');

/* Extends Backbone.Router to handle our routing table */
var Router = Backbone.Router.extend({
    _actions: {},
    _lastOperation: 0,

    /* Initializes the router */
    initialize: function initialize(options) {
        // generic entrypoint for all routing events
        this.on(
            'route',
            function onRoute(route, args) {
                var actionType = this._actions[route];
                var requestedAction;
                var behavior;
                var operation;

                if (!actionType) {
                    return;
                }

                log.write(
                    'Requested route: ' +
                        route +
                        ' (' +
                        window.location.hash +
                        ')'
                );

                // map 'null' string to actual null value
                _.each(args, function each(value, key) {
                    if (value === 'null') {
                        args[key] = null;
                    }
                });

                // if we've navigated to the current action, see if it can handle the request
                if (this.action instanceof actionType) {
                    if (this.action.handle.apply(this.action, args)) {
                        log.write('Request handled by existing action');
                        return;
                    }
                }

                // otherwise, activate a new action to deal with the route
                requestedAction = util.createObject(actionType, args);
                behavior = requestedAction.allowActivate();

                log.write(
                    '{' + route + '} action activation state: ' + behavior
                );

                // if allowActivate returns true, initialize and activate the action
                if (behavior === true) {
                    // sanity check the operation counter to ensure we don't load routes out of sequence
                    this._lastOperation += 1;
                    operation = this._lastOperation;

                    // initialize the action (potentially across a bundle load)
                    requestedAction
                        .initialize()
                        .then(
                            function then() {
                                // only activate the action if no subsequent route has been requested
                                if (this._lastOperation === operation) {
                                    this.action = requestedAction;
                                    this.trigger('routed', {
                                        action: requestedAction
                                    });
                                    return options.canvas.activate(this.action);
                                }

                                return undefined;
                            }.bind(this)
                        )
                        .catch(function catchErr(err) {
                            log.write(
                                'Error activating action for route ' + route,
                                err
                            );
                        });
                } else if (behavior === false) {
                    // if allowActivate returns false, restore the url for the current action
                    if (this.action) {
                        Backbone.history.navigate(this.action.getRoute(), {
                            trigger: false,
                            replace: true
                        });
                    }
                } else if (typeof behavior === 'string') {
                    // if allowActivate returns a new url, re-route and replace this request
                    Backbone.history.navigate(behavior, {
                        trigger: true,
                        replace: true
                    });
                } else {
                    // otherwise, something unexpected happened
                    throw new Error('Unknown activation state: ' + behavior);
                }
            }.bind(this)
        );
    },

    /* Loads the initial route and starts tracking history */
    start: function start(initialRoute) {
        if (!Backbone.history.start()) {
            Backbone.history.navigate(initialRoute, {
                trigger: true,
                replace: true
            });
        }
    },

    /* Navigate the app to the specified route fragment */
    navigate: function navigate(fragment, options) {
        return Backbone.history.navigate(fragment, options);
    },

    /* Register an action with the router */
    register: function register(name, action) {
        this._actions[name] = action;
        this.route(action.route, name);
    },

    /* Calculates a route to the specified action handler */
    getRoute: function getRoute(action, parameters) {
        var handler = this._actions[action];
        if (!handler) {
            throw new Error('Unknown action: ' + action);
        }

        return handler.getRoute.apply(handler, parameters || []);
    },

    /* Navigates the application to the back location specified by the current action */
    goBack: function goBack() {
        if (!this.action) {
            return;
        }

        if (this.action.onBackRequested) {
            this.action.onBackRequested(this._goBack.bind(this));
        } else {
            this._goBack();
        }
    },

    /* Internal handler for the goBack operation */
    _goBack: function _goBackInternal() {
        var location = this.action.getBackLocation();
        if (location) {
            Backbone.history.navigate(
                this.getRoute(location.route, location.parameters),
                { trigger: true }
            );
        }
    }
});

/* Export: Router type */
module.exports = Router;
