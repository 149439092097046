var localization = require('common/localization');
var CurrentUserViewModel = require('./currentUser/viewmodel');

module.exports = {
    id: 'auth',
    alwaysActive: true,
    actions: {
        login: require('./login/action'),
        logout: require('./logout/action'),
        passThru: require('./passThru/action'),
        passwordResetInvite: require('./passwordResetInvite/action'),
        registrationInvite: require('./registrationInvite/action'),
        passwordResetApply: require('./passwordResetApply/action'),
        registrationApply: require('./registrationApply/action'),
    },
    menu: function menu() {
        return [
            {
                label: ['Auth', 'Menu.LogOut'],
                class: 'menu-item-logout',
                action: 'auth-logout',
                priority: 1000
            }
        ];
    },
    init: function init(app) {
        localization.registerPhraseLibraryByKeys(require('./phrases'));
        app.header.add(new CurrentUserViewModel());
    }
};
