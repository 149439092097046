var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var ui = require('common/ui');
var ViewModel = require('common/viewmodel');
var log = require('utility/log');
var passwordResetInviteService = require('../services/passwordResetInvite');

require('./style.less');

function PasswordResetInviteViewModel() {
    var _this = this;
    _this.loading = ko.observable();

    _this.email = ko.observable();
    _this.complete = ko.observable(false);

    function submitInvite() {
        _this.loading(true);
        passwordResetInviteService
            .invoke(_this.email())
            .then(function then(res) {
                _this.complete(true);
            })
            .catch(function catchErr(err) {
                log.write('Error performing login', err);
                if (err.statusText === 'timeout') {
                    ui.notify(ui.localize('Generic', 'Message.RemoteTimeout'));
                } else {
                    ui.notify(
                        ui.localize(
                            'PasswordResetInvite',
                            'Message.UnknownError'
                        )
                    );
                }
            })
            .finally(function final() {
                _this.loading(false);
            });
    }

    _this.submit = function submit() {
        // validation
        if (!_this.email()) {
            ui.notify(
                ui.localize('PasswordResetInvite', 'Message.RequiredField')
            );
            return;
        }
        submitInvite();
    };

    _this.back = function back() {
        app.navigate('auth-login');
    };
}

// add ViewModel mixins
_.extend(PasswordResetInviteViewModel.prototype, ViewModel);

PasswordResetInviteViewModel.prototype.backToLogin = function backToLogin() {
    app.navigate('auth-login');
};

/* View template */
PasswordResetInviteViewModel.prototype.template = require('./view.html');

/* Export: PasswordResetInviteViewModel type */
module.exports = PasswordResetInviteViewModel;
