var ko = require('knockout');
var eventName = 'touchstart';

/* Creates an event handler for the touchstart event */
ko.bindingHandlers[eventName] = {
    init: function initTouch(
        element,
        valueAccessor,
        allBindings,
        viewModel,
        bindingContext
    ) {
        var newValueAccessor = function getEventValue() {
            var result = {};
            result[eventName] = valueAccessor();
            return result;
        };
        return ko.bindingHandlers.event.init.call(
            this,
            element,
            newValueAccessor,
            allBindings,
            viewModel,
            bindingContext
        );
    }
};
