var ko = require('knockout');
var DatePicker = require('../common/widgets/datepicker/datepicker');

/* Test two date objects for value equality */
function isEqualByDateValue(a, b) {
    if (!a && !b) {
        return true;
    }

    if (a && !b) {
        return false;
    }

    if (b && !a) {
        return false;
    }

    return a.getTime() === b.getTime();
}

/* Displays a date picker */
ko.bindingHandlers.datePicker = {
    init: function init(element, valueAccessor, allBindings) {
        var picker = new DatePicker();

        function valueUpdate() {
            var modelValue = valueAccessor();
            var elementValue = picker.getDate();

            if (
                !isEqualByDateValue(
                    ko.utils.unwrapObservable(modelValue),
                    elementValue
                )
            ) {
                ko.expressionRewriting.writeValueToProperty(
                    modelValue,
                    allBindings,
                    'datePicker',
                    elementValue
                );
            }
        }

        function modelUpdate() {
            var newValue = ko.utils.unwrapObservable(valueAccessor());
            var elementValue = picker.getDate();

            if (!isEqualByDateValue(newValue, elementValue)) {
                picker.setDate(newValue);
            }
        }

        picker.init(element, valueUpdate);

        ko.computed(modelUpdate, null, { disposeWhenNodeIsRemoved: element });
    },
    update: function update() {
        // not required
    }
};
