/* Request a remote package which belongs to this application */
function requestPackage(id, loadPackage) {
    switch (id) {
        case 'checklist':
            require.ensure([], function ensure() {
                loadPackage(require('./checklist/package'));
            });
            break;

        case 'conformanceReview':
            require.ensure([], function ensure() {
                loadPackage(require('./conformanceReview/package'));
            });
            break;

        case 'forms':
            require.ensure([], function ensure() {
                loadPackage(require('../components/forms/package'));
            });
            break;

        default:
            throw new Error('Unknown package: ' + id);
    }
}

/* Export: packageManager library */
module.exports = {
    requestPackage: requestPackage
};
