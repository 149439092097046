module.exports = {
    Login: {
        'Button.Login': 'Login',
        'Button.Back': 'Back',
        'Button.ForgotPassword': 'Forgot password?',
        'Button.Register': 'Register',
        'Label.Email': 'Email',
        'Label.Password': 'Password',
        'Label.Company': 'Company',
        'Panel.Login': 'Portal Login',
        'Message.InvalidUser':
            'No user could be found with this username/password combination',
        'Message.PermissionError':
            'This user account is not permitted to use the portal',
        'Message.UnknownError':
            'An error occured while authenticating your credentials; please try again',
        'Message.RequiredFields': 'Please enter your username and password',
        'Message.RequiredFieldsCompany': 'Please select a company',
        'Message.InvalidFormRequest':
            'The form requested is no longer available'
    },
    Auth: {
        'Menu.LogOut': 'Log Out',
        'Message.SessionExpired':
            'Your user session has expired; please log in again'
    },
    PasswordResetApply: {
        'Label.User': 'User',
        'Label.ConfirmPassword': 'Confirm Password',
        'Panel.PasswordReset': 'Password Reset',
        'Message.Error': 'Unable to process the password reset request as the provided reset token is invalid. The request may have expired in which case you can re-start the process by clicking the "Forgot Password" button below.',
        'Message.ResetOk': 'Your password has been successfully reset.',
        'Button.Reset': 'Reset password',
        'Message.Help': 'Please enter your new password:'
    },
    PasswordResetInvite: {
        'Label.Email': 'Email',
        'Button.Submit': 'Request new password',
        'Panel.ResetPassword' : 'Password Recovery',
        'Message.Help' : 'To reset your password, please enter your username below. Further instructions will be sent to the email address associated with your account.',
        'Message.RequiredField': 'Please enter your email address',
        'Message.PasswordResetSubmitted' : 
            'Your password recovery request was successful; please check your email for further instructions.',
        'Message.UnknownError': 'An unexpected error occured whilst requesting your password reset.'
    },
    RegistrationInvite: {
        'Label.Email': 'Email',
        'Button.Submit': 'Request registration',
        'Panel.Register' : 'Registration Request',
        'Message.Help' : 'To enable access to Audit-Hub using the self registration feature please enter your email below, further instructions will be sent to the email address entered.',
        'Message.RequiredField': 'Please enter your email address',
        'Message.RegistrationInviteSubmitted' : 
            'Your account registration request was successful; please check your email for further instructions.',
        'Message.RegistrationInviteFailed' : 
            'It has not been possible to send an email to your email address as this account either does not exist or has not been configured for self registration, please contact your system administrator for technical support.',            
        'Message.UnknownError': 'An error occured while making the request, please try again'
    },
    RegistrationApply: {
        'Panel.Registration': 'Confirm Registration',
        'Message.Help': 'To confirm your registration enter a valid password below, you can then access the Audit-Hub application using the entered email and password.',
        'Button.Register': 'Register',
        'Message.Error': 'It has not been possible to retrieve details relating to the supplied registration request.',
        'Message.Error.Bullet1': 'Your registration link may have expired, if this is the case you may request a new registration link by clicking the "Register" button below.',
        'Message.Error.Bullet2': 'However your account may no longer be in a valid state for self registration in this case please contact your System Administrator.',
        'Message.RegisterOk': 'Your account has been successfully created, you can now sign into the application using your email address and chosen password.'
    }
};
