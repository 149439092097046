var ko = require('knockout');
var _ = require('lodash');

// update the indent for the element to the specified level
function update(element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());

    if (_.isFinite(value)) {
        value = {
            size: 1,
            level: value,
            offset: 0
        };
    }

    element.style.paddingLeft =
        Number(value.size) * Number(value.level) + Number(value.offset) + 'px';
}

/* Sets a left-indent on an element */
ko.bindingHandlers.indent = {
    init: update,
    update: update
};
