var _ = require('lodash');
var ko = require('knockout');
var Backbone = require('backbone');
var ui = require('common/ui');
var ViewModel = require('../viewmodel');

require('./style.less');

/* ViewModel used to display a Back button to the user */
function BackButtonViewModel(app) {
    var _this = this;

    this.backLocation = ko.observable('');
    this.priority = 2;

    this.goBack = function goBack() {
        app.onBackButton();
    };

    this.listenTo(app.router, 'routed', function onRoute(args) {
        var backLocation = args.action.getBackLocation();
        var title = null;

        if (backLocation) {
            title = backLocation.title || ['Generic', 'Header.Back'];
            title = ui.localize.apply(ui, title);
        }

        _this.backLocation(title);
    });
}

// add ViewModel mixins
_.extend(BackButtonViewModel.prototype, ViewModel);

// add Event mixins
_.extend(BackButtonViewModel.prototype, Backbone.Events);

/* View template */
BackButtonViewModel.prototype.template = require('./view.html');

/* Export: BackButtonViewModel type */
module.exports = BackButtonViewModel;
