var ko = require('knockout');
var autosize = require('autosize');

/* Automatically adjusts the size of a text area based on the size of the content */
ko.bindingHandlers.autosize = {
    init: function initAutosize(element) {
        autosize(element);
    },
    update: function updateAutosize(element, valueAccessor) {
        ko.utils.unwrapObservable(valueAccessor());

        autosize.update(element);
    }
};
