var runtime = {};

/* Static configuration values for the application */
runtime.config = {
    loginRoute: 'auth/login',
    defaultRoute: 'home-dashboard',
    remoteServicePath: 'services/portal/{service}.svc'
};

if (__DEBUG__) {
    /* Default values for application settings */
    runtime.settings = {
        remoteHost: 'http://localhost:44304'
    };

    /* Debugging configuration */
    runtime.debug = {
        logTimestamp: true,
        serviceHost: 'http://localhost:44305',
        username: 'andy@thecodeclinic.co.uk',
        password: 'Leicester1884?'
    };
} else {
    /* Default values for application settings */
    runtime.settings = {
        remoteHost: 'https://fssi-auth.audit-hub.com'
    };

    /* Debugging configuration */
    runtime.debug = {
        logTimestamp: false
    };
}

/* Export: runtime configuration */
module.exports = runtime;
