var _ = require('lodash');
var app = require('common/app');
var ko = require('knockout');
var PackageWatchingViewModel = require('../../generic/packageWatchingViewModel');
var ConfirmExitViewModel = require('common/confirmExit/viewmodel');
var DashboardGroupViewModel = require('./dashboardGroup/viewmodel');
var async = require('utility/async');
var util = require('utility/util');
var getFormResponseService = require('../../forms/services/getFormResponse');
var ui = require('common/ui');

require('./style.less');

/* ViewModel used to display the user's dashboard */
function DashboardViewModel() {
    var _this = this;
    var knownProviders = {};
    var monitor = new async.Monitor();

    PackageWatchingViewModel.call(_this, 'dashboard');

    // confirm exit dialog
    _this.confirmExit = new ConfirmExitViewModel();

    // observable properties
    _this.loading = ko.computed(monitor.outstanding);
    _this.dashboardComponents = ko.observableArray().extend({ rateLimit: 25 });

    // computed properties
    _this.hasComponents = ko.computed(function getHasComponents() {
        return !!_this.dashboardComponents().length;
    });

    // all known components grouped by key
    _this.dashboardGroups = ko.computed(function getDashboardGroups() {
        var components = _this.dashboardComponents();
        var groupData = _.groupBy(components, function groupBy(component) {
            return component.group.key;
        });
        var groups = [];

        _.forIn(groupData, function forIn(value) {
            groups.push(
                new DashboardGroupViewModel(
                    value[0].group,
                    _.map(value, 'component'),
                    _this.loading
                )
            );
        });

        groups.sort(function sortByTitle(a, b) {
            return a.title.localeCompare(b.title);
        });

        return groups;
    });

    // force all component providers to update by resetting the known provider list
    _this.refreshComponents = function refreshComponents() {
        knownProviders = {};
        _this._components.valueHasMutated();
    };

    // listen to package changes and load components from any exposed provider
    _this.trackDisposable(
        ko.computed(function watchPackages() {
            var availableProviders = _this._components();

            _.each(availableProviders, function loadProvider(provider) {
                if (knownProviders[provider.id]) {
                    return;
                }

                monitor.begin();

                knownProviders[provider.id] = provider
                    .getComponents(monitor)
                    .then(function handleComponents(res) {
                        _.each(res, function pushComponent(o) {
                            _this.dashboardComponents.push(o);
                        });

                        return undefined;
                    })
                    .catch(
                        _this.catch(
                            'Error loading dashboard components for provider ' +
                                provider.id
                        )
                    )
                    .finally(function loadProviderComplete() {
                        monitor.complete();
                    });
            });
        })
    );

    _this.initialize();
}

/* DashboardViewModel extends PackageWatchingViewModel */
DashboardViewModel.prototype = new PackageWatchingViewModel();

DashboardViewModel.prototype.getFormRequest = function getFormRequest() {
    return util.getQueryStringParameterByName(
        'FormRequestId',
        window.location.href
    );
};

DashboardViewModel.prototype.getFormReviewBlock = function getFormReviewBlock() {
    return util.getQueryStringParameterByName(
        'FormReviewBlockId',
        window.location.href
    );
};

DashboardViewModel.prototype.getFormReview = function getFormReview() {
    return util.getQueryStringParameterByName(
        'FormReviewId',
        window.location.href
    );
};

/* Initializes the action in preparation for activation */
DashboardViewModel.prototype.initialize = function initialize() {
    var _this = this;

    setTimeout(function performRedirects() {
        
        var formRequestId = _this.getFormRequest();
        var formReviewId = _this.getFormReview();
        var formReviewBlockId = _this.getFormReviewBlock();

        if (formRequestId) {
            util.removeQueryStringParameter('FormRequestId');
            getFormResponseService
                .invoke(formRequestId)
                .then(function success(response) {
                    return app.navigate('forms-form', [response.formResponseId, 1]);
                })
                .catch(function invalidResponse() {
                    ui.notify(
                        ui.localize('Home.Dashboard', 'Message.InvalidResponse')
                    );
                });
        }
    
        if (formReviewBlockId && formReviewId) {
            util.removeQueryStringParameter('FormReviewId');
            util.removeQueryStringParameter('FormReviewBlockId');
    
            return app.navigate('conformanceReview-reviewBlockQuestions', [
                formReviewId,
                formReviewBlockId
            ]);
        }
    }, 250);


    return undefined;
};

/* View template */
DashboardViewModel.prototype.template = require('./view.html');

/* Export: DashboardViewModel type */
module.exports = DashboardViewModel;
