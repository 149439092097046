var _ = require('lodash');
var ko = require('knockout');
var Backbone = require('backbone');
var app = require('common/app');
var gc = require('utility/gc');

require('./style.less');

/* ViewModel used to display the current user in the header */
function CurrentUserViewModel() {
    self.currentUser = ko.observable();

    this.listenTo(app, 'change:user', this._refreshUser.bind(this));
}

// add Event mixins
_.extend(CurrentUserViewModel.prototype, Backbone.Events);

/* View template */
CurrentUserViewModel.prototype.template = require('./view.html');

/* Refresh the viewmodel with the current app user */
CurrentUserViewModel.prototype._refreshUser = function _refreshUser(user) {
    self.currentUser((user && user.title) || '');
};

/* Cleans up memory allocations when disposing of the PackageWatchingViewModel */
CurrentUserViewModel.prototype.dispose = function dispose() {
    this.stopListening();
    gc.releaseKeys(this);
};

/* Export: CurrentUserViewModel type */
module.exports = CurrentUserViewModel;
