var remoteService = require('common/services/remote/remoteService');
var beginSession = require('./beginSession');

/* Attempts to authenticate a user with the specified credentials */
function login(username, password, companyId) {
    return remoteService
        .getUri('auth', 'login')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                username: username,
                password: password,
                companyId: companyId
            });
        })
        .then(function then(res) {
            if (res.availableCompanies) {
                return res;
            } else if (res.authenticatedUser) {
                return beginSession.invoke(res.authenticatedUser);
            }

            throw new Error('Unexpected response from auth/login');
        });
}

/* Export: login service */
module.exports = {
    invoke: login
};
