var ko = require('knockout');

var objectIsReleasedMarker = '__gc_released';

/* Checks an object to determine if it can be released */
function isReleaseable(obj, depth) {
    var value;
    var key;

    if (!obj || obj !== Object(obj) || obj[objectIsReleasedMarker]) {
        return false;
    }

    if (ko.isObservable(obj)) {
        return true;
    }

    if (typeof obj === 'function') {
        return false;
    }

    if (typeof obj.dispose === 'function') {
        return true;
    }

    if (depth > 0) {
        return false;
    }

    for (key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
            value = obj[key];
            if (key !== objectIsReleasedMarker && isReleaseable(value, 1)) {
                return true;
            }
        }
    }

    return false;
}

/* Releases all values in an object */
function releaseKeys(obj) {
    var value;
    var key;

    for (key in obj) {
        if ({}.hasOwnProperty.call(obj, key)) {
            value = obj[key];
            obj[key] = null;

            // eslint-disable-next-line no-use-before-define
            release(value);
        }
    }
}

/* Release memory used by an object */
function release(obj) {
    // ensure we can release the provided object
    if (!isReleaseable(obj)) {
        return;
    }

    // ensure we don't re-release this object in a loop
    obj[objectIsReleasedMarker] = true;

    // if the object exposes a dispose method, let it take care of it's own cleanup
    if (typeof obj.dispose === 'function') {
        obj.dispose();
        return;
    }

    // if it's an observable value, unwrap and release the contents
    if (ko.isObservable(obj)) {
        release(
            ko.ignoreDependencies(function ignoreDependencies() {
                return obj();
            })
        );
        return;
    }

    // release the object contents
    releaseKeys(obj);
}

/* Automatically release an object when a node is removed from the document */
function releaseOnNodeRemove(obj, node) {
    return ko.utils.domNodeDisposal.addDisposeCallback(
        node,
        function addDisposeCallback() {
            return release(obj);
        }
    );
}

/* Exports: garbage collection namespace */
module.exports = {
    release: release,
    releaseKeys: releaseKeys,
    releaseOnNodeRemove: releaseOnNodeRemove
};
