var remoteService = require('common/services/remote/remoteService');
var beginSession = require('./beginSession');

/* Attempts to authenticate a user using the security token passed from web app */
function passThru(securityToken) {
    return remoteService
        .getUri('auth', 'passThru')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                securityToken: securityToken
            });
        })
        .then(function then(res) {
            if (res.availableCompanies) {
                return res;
            } else if (res.authenticatedUser) {
                return beginSession.invoke(res.authenticatedUser);
            }

            throw new Error('Unexpected response from auth/passThru');
        });
}

/* Export: passThru service */
module.exports = {
    invoke: passThru
};
