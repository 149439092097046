var _ = require('lodash');
var Promise = require('promise');
var app = require('common/app');
var remoteService = require('common/services/remote/remoteService');
var log = require('utility/log');
var persistentSession = require('./persistentSession');

/* Checks for the existence of a saved session and attempts to re-join that session on the server */
function restoreSession() {
    var userData = persistentSession.load();

    // no session to resume
    if (!userData || !userData.realmUri) {
        return Promise.resolve();
    }

    log.write('Resuming remote session on ' + userData.realmUri);

    // retrieve the authenticated user from the remote service
    return remoteService
        .getUri('auth', 'restoreSession', userData.realmUri)
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                sessionToken: userData.sessionToken
            });
        })
        .then(function then(res) {
            var user;

            if (res.authenticatedUser) {
                user = _.extend({}, res.authenticatedUser, res.session);
                log.write(
                    'Restored sesson for user ' +
                        user.userId +
                        ' (' +
                        user.title +
                        ')'
                );

                return app.changeUser(user, true);
            }

            return undefined;
        })
        .catch(function catchErr(err) {
            log.write('Error resuming remote session', err);

            // this operation always succeeds; either we resume the session or we don't
            return undefined;
        });
}

/* Exports: restoreSession service */
module.exports = {
    invoke: restoreSession
};
