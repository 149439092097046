var cookie = require('platform/cookie');
var cookieId = 'ccFedAuth';

function splitString(string, size) {
    var re = new RegExp('.{1,' + size + '}', 'g');
    return string.match(re);
}

function cleanUp(start) {
    var i = start;
    var value = cookie.get(`${cookieId}${i}`);
    while (value) {
        cookie.remove(`${cookieId}${i}`);
        i = i + 1;
        value = cookie.get(`${cookieId}${i}`);
    }
}

function save(userData) {
    var chunks;
    var COOKIE_SIZE = 2000;

    if (!userData) {
        cleanUp(1);
        return;
    }

    chunks = splitString(userData.securityToken, COOKIE_SIZE);
    for (let i = 0; i < chunks.length; i = i + 1) {
        cookie.set(`${cookieId}${i + 1}`, chunks[i]);
    }
    cleanUp(chunks.length + 1);
}

function load() {
    var i = 1;
    var fullValue = '';
    var value = cookie.get(`${cookieId}${i}`);
    while (value) {
        fullValue += value;
        i = i + 1;
        value = cookie.get(`${cookieId}${i}`);
    }
    return fullValue;
}

/* Exports: local storage for session data */
module.exports = {
    save: save,
    load: load
};
