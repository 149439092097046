var app = require('common/app');
var Action = require('common/actions/action');
var RegistrationApplyViewModel = require('./viewmodel');
var util = require('utility/util');

/* Action used to apply for a password reset */
function RegistrationApplyAction(userId, token) {
    var _this = this;
    _this.token = token;
    _this.userId = userId;
}

/* Action inheritance */
RegistrationApplyAction.prototype = new Action();

/* Single pane style control */
RegistrationApplyAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
RegistrationApplyAction.route = 'auth/registrationApply/:userId/:token';

/* Returns a route containing the specified parameters */
RegistrationApplyAction.getRoute = function getRoute(userId, token) {
    return 'auth/registrationApply/' + userId + '/' + token;
};

/* Returns the route which will display the current state of this Action */
RegistrationApplyAction.prototype.getRoute = function getRoute() {
    return RegistrationApplyAction.getRoute(_this.userId, _this.token);
};

/* RegistrationApplyAction can only be activated if the user is not authenticated */
RegistrationApplyAction.prototype.allowActivate = function allowActivate() {
    if (app.currentUser()) {
        return 'dashboard';
    }
    return true;
};

/* Allow the app to exit from this action */
RegistrationApplyAction.prototype.onBackRequested = function onBackRequested() {
    this.viewModel.confirmExit.notify();
};

/* Initializes the action in preparation for activation */
RegistrationApplyAction.prototype.initialize = function initialize() {
    var _this = this;
    _this.viewModel = new RegistrationApplyViewModel(_this.userId, _this.token);
    util.removeQueryStringParameters();
    return  _this.viewModel.initialize();
};

/* Export: RegistrationApplyAction type */
module.exports = RegistrationApplyAction;
