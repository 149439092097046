var Promise = require('promise');

// always use Bluebird for Promise
global.Promise = Promise;
Promise.config({
    cancellation: true
});

// polyfill fetch in IE
require('whatwg-fetch');

// polyfill to resolve IE not supporting element.closest
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
  }
  
  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      var el = this;
  
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }