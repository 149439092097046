module.exports = {
    Generic: {
        Yes: 'Yes',
        No: 'No',
        None: 'None',
        'Dialog.Confirm': 'Please Confirm',
        'Dialog.Notify': 'Information',
        'Button.OK': 'OK',
        'Button.Cancel': 'Cancel',
        'Button.Save': 'Save',
        'Button.Submit': 'Submit',
        'Button.Download': 'Download',
        'Button.Edit': 'Edit',
        'Button.Remove': 'Remove',
        'Button.Details': 'Details',
        'Button.Back': 'Back',
        'Button.BackToLogin': 'Back to login',
        'Button.Previous': 'Previous',
        'Button.Next': 'Next',
        'Message.ConfirmExit': 'Press back again to exit',
        'Message.UnknownError': 'An error has occurred; please try again',
        'Message.RemoteTimeout':
            'The remote server could not be contacted; please check your internet connection and try again',
        'Message.Loading': 'Loading...',
        'Message.PleaseWait':
            'Please wait for the current operation to complete',
        'Message.ValidationSummary': 'Please correct the following:',
        'Header.Back': 'Back',
        'Input.Empty': '[Select]'
    },
    Menu: {
        'Header.Menu': 'Menu'
    },
    Validation: {
        'Message.ErrorsExist':
            'Correct the following data entry errors before saving:',
        Required: '{0} is required',
        MaxLength: '{0} must be less than {1} characters'
    },
    Header: {
        'Location.Menu': 'Menu',
        'Location.Form': 'Return to Audit',
        'Location.ListForms': 'Audit List',
        'Location.Back': 'Back',
        'Location.Results': 'Audit Results',
        'Location.Home': 'Home'
    }
};
