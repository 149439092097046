var localization = require('common/localization');

module.exports = {
    id: 'home',
    alwaysActive: true,
    actions: {
        dashboard: require('./dashboard/action')
    },
    menu: function menu() {
        return [
            {
                label: ['Home', 'Menu.Home'],
                class: 'menu-item-home',
                action: 'home-dashboard',
                priority: 1
            }
        ];
    },
    init: function init() {
        localization.registerPhraseLibraryByKeys(require('./phrases'));
    }
};
