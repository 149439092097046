var ko = require('knockout');
var bindingKey = 'withExisting';

// Modified version of the 'with' binding to check for the existence of a value before transferring control, to streamline markup
ko.bindingHandlers[bindingKey] = {
    /* As ko.bindingHandlers['with'].init, except:
     *   shouldDisplay is always calculated based on object existence.
     *   Removed check for needsRefresh as 'with' always refreshes.
     *   Inlined createChildContext as this always occurs
     */
    init: function init(
        element,
        valueAccessor,
        allBindings,
        viewModel,
        bindingContext
    ) {
        var savedNodes;
        ko.computed(
            function computed() {
                var dataValue = ko.utils.unwrapObservable(valueAccessor());
                var shouldDisplay = !!dataValue;
                var isFirstRender = !savedNodes;

                // Save a copy of the inner nodes on the initial update, but only if we have dependencies.
                if (
                    isFirstRender &&
                    ko.computedContext.getDependenciesCount()
                ) {
                    savedNodes = ko.utils.cloneNodes(
                        ko.virtualElements.childNodes(element),
                        true
                    );
                }

                if (shouldDisplay) {
                    if (!isFirstRender) {
                        ko.virtualElements.setDomNodeChildren(
                            element,
                            ko.utils.cloneNodes(savedNodes)
                        );
                    }
                    ko.applyBindingsToDescendants(
                        bindingContext.createChildContext(dataValue),
                        element
                    );
                } else {
                    ko.virtualElements.emptyNode(element);
                }
            },
            null,
            { disposeWhenNodeIsRemoved: element }
        );

        return {
            controlsDescendantBindings: true
        };
    }
};

// Can't rewrite control flow bindings
ko.expressionRewriting.bindingRewriteValidators[bindingKey] = false;
ko.virtualElements.allowedBindings[bindingKey] = true;
