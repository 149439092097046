var ko = require('knockout');
var gc = require('utility/gc');

/* Template binding with automatic garbage collection */
ko.bindingHandlers.managedTemplate = {
    init: function init() {
        return ko.bindingHandlers.template.init.apply(this, arguments);
    },
    update: function update(
        element,
        valueAccessor,
        allBindings,
        viewModel,
        bindingContext
    ) {
        ko.bindingHandlers.template.update.apply(this, arguments);

        gc.releaseOnNodeRemove(bindingContext.$rawData, element);
    }
};

/* Allow virtual elements for this binding handler */
ko.virtualElements.allowedBindings.managedTemplate = true;
