var Promise = require('promise');
var runtime = require('app/runtime');

/* Returns the value of a setting with the specified name */
function get(name) {
    var err;

    // browser implementations always use the default values
    if (runtime.settings[name]) {
        return Promise.resolve(runtime.settings[name]);
    }

    err = new Promise.OperationalError('Not Found');
    err.status = 404;
    return Promise.reject(err);
}

/* Sets the value of a setting with the specified name */
function set() {
    // browser implementations do not store setting changes
    return Promise.resolve(true);
}

/* Resets all settings to their default values */
function reset() {
    // browser implementations do not store setting changes
    return Promise.resolve(true);
}

/* Export: settings management utilities */
module.exports = {
    get: get,
    set: set,
    reset: reset
};
