var remoteService = require('common/services/remote/remoteService');

/* Attempts to reset the users password */
function passwordResetApply(userId, token, password, confirmPassword) {
    return remoteService
        .getUri('auth', 'passwordResetApply')
        .then(function then(serviceUri) {
            return remoteService.invoke(serviceUri, {
                userId: userId,
                token: token,
                password: password,
                confirmPassword: confirmPassword
            });
        });
}

/* Export: passwordResetApply service */
module.exports = {
    invoke: passwordResetApply
};