var _ = require('lodash');
var ViewModel = require('common/viewmodel');

require('./style.less');

/* ViewModel to be displayed while the user is logged out of the application */
function LogoutViewModel() {
    // no constructor
}

// add ViewModel mixins
_.extend(LogoutViewModel.prototype, ViewModel);

/* View template */
LogoutViewModel.prototype.template = require('./view.html');

/* Export: LogoutViewModel type */
module.exports = LogoutViewModel;
