var _ = require('lodash');
var Promise = require('promise');
var ko = require('knockout');

/* Defers initialization of a value until it is accessed for the first time */
function lazy(factory) {
    var promise;
    var accessor;

    accessor = function getValue() {
        if (promise) {
            return promise;
        }

        promise = Promise.resolve(factory());

        return promise;
    };

    accessor.reset = function resetValue() {
        promise = null;
    };

    return accessor;
}

/* Wraps a method call which accepts success and error callbacks via an options parameter and converts it to a trusted Promise */
function defer(obj, method, opts, argsBefore, argsAfter) {
    return new Promise(function deferAsync(resolve, reject) {
        // eslint-disable-next-line no-invalid-this
        var _this = this;
        var success;
        var error;
        var args;
        var options;

        // verify function to invoke
        if (!obj[method] || typeof obj[method] !== 'function') {
            throw new Error('No method named ' + method + ' found');
        }

        // inspect options
        options = opts || {};
        success = options.success;
        error = options.error;

        // create success/error callbacks
        options.success = function onSuccess() {
            if (success) {
                success.apply(this, arguments);
            }
            resolve.apply(_this, arguments);
        };
        options.error = function onError() {
            if (error) {
                error.apply(this, arguments);
            }
            reject.apply(_this, arguments);
        };

        // build the function invocation
        args = [];
        if (argsBefore) {
            args = args.concat(argsBefore);
        }
        args.push(options);
        if (argsAfter) {
            args = args.concat(argsAfter);
        }

        // invoke the function
        obj[method].apply(obj, args);
    });
}

/* Gets a nested value from an object which may contain promises */
function getByPath(source) {
    var path = Array.prototype.slice.call(arguments, 1);
    var step;
    var walk;

    if (!path.length) {
        return Promise.resolve(source);
    }

    step = path.shift();

    if (step instanceof Array) {
        walk = Promise.all(
            _.map(step, function map(key) {
                return Promise.resolve(_.result(source, key));
            })
        ).then(function createNextSource(res) {
            return _.zipObject(step, res);
        });
    } else {
        walk = Promise.resolve(_.result(source, step));
    }

    return walk.then(function nextStep(nextSource) {
        if (nextSource === undefined) {
            return undefined;
        }

        return getByPath.apply(undefined, [nextSource].concat(path));
    });
}

/* Monitors the number of outstanding async operations */
function Monitor() {
    var _this = this;

    _this._counter = ko.observable(0);
    _this._waiting = [];
    _this.outstanding = ko.computed(function getOutstanding() {
        return _this._counter() > 0;
    });

    _this.outstanding.subscribe(function onOutstandingChanged(newValue) {
        if (!newValue) {
            _this._next();
        }
    });
}

/* Indicates that an async operation has begun */
Monitor.prototype.begin = function begin() {
    this._counter(this._counter() + 1);
};

/* Indicates that an async operation has completed */
Monitor.prototype.complete = function complete() {
    this._counter(this._counter() - 1);
};

/* Queues an operation to be performed when the monitor is next available */
Monitor.prototype.wait = function wait(operation) {
    if (this.outstanding()) {
        this._waiting.push(operation);
    } else {
        operation();
    }
};

/* Performs the next operation in the queue */
Monitor.prototype._next = function _next() {
    var operation = this._waiting.shift();
    if (operation) {
        operation();

        if (!this.outstanding()) {
            this._next();
        }
    }
};

/* Wraps a method to update the outstanding operation counter as it executes */
Monitor.prototype.wrap = function wrap(method, context) {
    var _this = this;

    return function executeMethod() {
        var result;

        _this.begin();
        // eslint-disable-next-line no-invalid-this
        result = method.apply(context || this, arguments);

        if (typeof result.promise === 'function') {
            result.promise().finally(function onResult() {
                _this.complete();
            });
        } else {
            _this.complete();
        }

        return result;
    };
};

/* Performs a lazy initialization of a value while updating the outstanding operation counter */
Monitor.prototype.lazy = function monitoredLazy(factory, initialize) {
    var _this = this;
    var accessor = lazy(factory, initialize);

    return function getValue() {
        _this.begin();
        return accessor().tap(function onValueResolved() {
            _this.complete();
        });
    };
};

/* Export: async operations */
module.exports = {
    lazy: lazy,
    defer: defer,
    getByPath: getByPath,
    Monitor: Monitor
};
