var runtime = require('app/runtime');
var moment = require('moment');

var formatLogPartition;

if (runtime.debug.logTimestamp) {
    formatLogPartition = function formatPartition(partition) {
        var now = moment();
        var time = now.format('HH:mm:ss.SSS');

        return '[' + time + '] [' + partition + '] ';
    };
} else {
    formatLogPartition = function formatPartition(partition) {
        return '[' + partition + '] ';
    };
}

/* Converts a value to a string representation */
function stringify(value) {
    switch (typeof value) {
        case 'undefined':
            return 'undefined';

        case 'object':
            if (value === null) {
                return 'null';
            }
            if (value instanceof Error) {
                if (value.stack) {
                    return value.stack;
                }
                return value.toString();
            }
            return JSON.stringify(value);

        default:
            return value + '';
    }
}

/* Formats a message for logging */
function format(message, object) {
    if (arguments.length > 1) {
        return stringify(message) + ': ' + stringify(object);
    }

    return stringify(message);
}

/* Writes a log entry to the specified partition */
function writeEntry(partition, logEntry) {
    // eslint-disable-next-line no-console
    return console.log(formatLogPartition(partition) + logEntry);
}

/* Writes a message to the system log */
// eslint-disable-next-line no-unused-vars
function write(message, object) {
    return writeEntry('app', format.apply(undefined, arguments));
}

/* Writes a message to the trace log */
// eslint-disable-next-line no-unused-vars
function trace(message, object) {
    return writeEntry('trace', format.apply(undefined, arguments));
}

/* Export: logging library */
module.exports = {
    format: format,
    write: write,
    trace: trace
};
