var localization = require('common/localization');
var dialog = require('./dialog');

/* Retrieves localized text with the specified class and resource keys */
function localize(classKey, resourceKey) {
    return localization.getPhrase(classKey, resourceKey);
}

/* Displays a message to the user with a simple 'OK' button */
function notify(message, onDismiss) {
    dialog.messageBox(
        localize('Generic', 'Dialog.Notify'),
        message,
        [
            {
                text: localize('Generic', 'Button.OK'),
                isDefault: true
            }
        ],
        {
            style: 'modal-dialog notify',
            events: {
                hide: function hide(event, api) {
                    api.destroy();

                    if (onDismiss) {
                        setTimeout(onDismiss);
                    }
                }
            }
        }
    );
}

/* Displays a message to the user with 'Confirm' and 'Cancel' buttons */
function confirm(message, onConfirm, onCancel) {
    var confirmed = false;

    dialog.messageBox(
        localize('Generic', 'Dialog.Confirm'),
        message,
        [
            {
                text: localize('Generic', 'Button.OK'),
                action: function onAction() {
                    confirmed = true;
                    this.dismiss();
                }
            },
            {
                text: localize('Generic', 'Button.Cancel'),
                isDefault: true
            }
        ],
        {
            style: 'modal-dialog confirm',
            events: {
                hide: function onHide(event, api) {
                    api.destroy();

                    if (confirmed) {
                        if (onConfirm) {
                            setTimeout(onConfirm);
                        }
                    } else if (onCancel) {
                        setTimeout(onCancel);
                    }
                }
            }
        }
    );
}

/* Export: ui library */
module.exports = {
    localize: localize,
    notify: notify,
    confirm: confirm
};
