var ko = require('knockout');

var TIMEOUT = 2500;

require('./style.less');

/* ViewModel used to navigate to key areas of the system */
function ConfirmExitViewModel() {
    var _this = this;

    // observable properties
    _this.visible = ko.observable(false);

    // logic
    _this.visible.subscribe(function onVisibleChanged(newValue) {
        if (newValue) {
            setTimeout(function onTimeout() {
                _this.visible(false);
            }, TIMEOUT);
        }
    });
}

/* View template */
ConfirmExitViewModel.prototype.template = require('./view.html');

/* Display the exit confirmation message */
ConfirmExitViewModel.prototype.notify = function notify() {
    if (this.visible()) {
        navigator.app.exitApp();
    } else {
        this.visible(true);
    }
};

/* Export: ConfirmExitViewModel type */
module.exports = ConfirmExitViewModel;
