var _ = require('lodash');
var $ = require('jquery');
var ko = require('knockout');
var behavior;

require('qtip2');
require('qtip2/dist/jquery.qtip.css');
require('./style/dialog.less');

/* Collection of behaviors which can be applied to dialogs */
behavior = {
    /* Render behavior for a dialog which includes a default action */
    defaultAction: function defaultAction(action) {
        function keypressHandler(e) {
            if (e.which === 13) {
                e.preventDefault();

                // eslint-disable-next-line no-use-before-define
                doAction();
            }
        }

        function doAction() {
            action();
            $(document).off('keypress', keypressHandler);
        }

        $(document).on('keypress', keypressHandler);

        return doAction;
    },

    /* Render behavior for a dialog which can be dismissed with the Enter key */
    dismissable: function dismissable(api) {
        return behavior.defaultAction(function defaultAction() {
            api.hide();
        });
    },

    /* Safely destroys a dialog via api reference */
    destroy: function destroy(api) {
        var frames = $('iframe', api.elements.content);
        var interval = setInterval(function onInterval() {
            var frame;
            if (frames.length) {
                frame = Array.prototype.pop.apply(frames);
                frame.src = '';
                $(frame).remove();
            } else {
                clearInterval(interval);
                api.destroy();
            }
        }, 1);
    }
};

/* Opens a qTip modal dialog with a set of default options */
function openDialog(options) {
    var optionsWithDefault;

    if (document.activeElement) {
        document.activeElement.blur();
    }

    optionsWithDefault = _.merge(
        {
            position: {
                my: 'center',
                at: 'center',
                target: $(window)
            },
            show: {
                ready: true,
                modal: {
                    on: true,
                    blur: false
                }
            },
            hide: false,
            style: 'modal-dialog',
            events: {
                hide: function onHide(event, api) {
                    behavior.destroy(api);
                }
            }
        },
        options
    );

    if (
        optionsWithDefault.content &&
        optionsWithDefault.content.button === 'close'
    ) {
        optionsWithDefault.content.button = $('<a href="#" class="close" />');
    }

    $('<div />').qtip(optionsWithDefault);
}

/* Opens a qTip modal dialog displaying a message and a number of option buttons */
function messageBox(title, message, buttons, options) {
    openDialog(
        _.merge(
            {
                content: {
                    title: title,
                    text: require('./dialog-messageBox.html')
                },
                style: 'modal-dialog message-box',
                events: {
                    render: function render(e, api) {
                        var viewModel = {
                            message: message,
                            buttons: [],
                            dismiss: function dismiss() {
                                api.hide();
                            }
                        };
                        var foundDefault;

                        _.each(buttons || [], function each(button) {
                            var model = {
                                text: button.text || '',
                                action: button.action || viewModel.dismiss,
                                isDefault: !!button.isDefault && !foundDefault,
                                dismiss: viewModel.dismiss
                            };

                            if (model.isDefault) {
                                model.action = behavior.defaultAction(
                                    model.action
                                );
                                foundDefault = true;
                            }

                            viewModel.buttons.push(model);
                        });

                        ko.applyBindings(viewModel, api.elements.content[0]);
                    }
                }
            },
            options
        )
    );
}

/* Export: dialog library */
module.exports = {
    open: openDialog,
    messageBox: messageBox,
    behavior: behavior
};
