var Action = require('../../../common/actions/action');
var dom = require('utility/dom');

/* Enable the 'single-pane' body style when activated */
Action.prototype.onActivate = function onActivate() {
    dom.toggleClass(
        document.getElementsByTagName('body')[0],
        'single-pane',
        this.isSinglePane
    );
};

/* Export: Action type */
module.exports = Action;
