/* Type representing the element which contains view content */
function Canvas(containerId) {
    this.container = document.getElementById(containerId);
}

/* Activate the specified view, displaying it on the canvas */
Canvas.prototype.activate = function activate(action) {
    if (this._current && this._current.onClear) {
        this._current.onClear(this);
    }

    while (this.container.firstChild) {
        this.container.removeChild(this.container.firstChild);
    }
    this.container.appendChild(action.view());
    this._current = action;

    if (action.onActivate) {
        action.onActivate(this);
    }

    return null;
};

/* Export: Canvas type */
module.exports = Canvas;
