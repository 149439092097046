var Action = require('common/actions/action');
var PassThruViewModel = require('./viewmodel');
var util = require('utility/util');

/* Action used to authenticate the user with the app */
function PassThruAction(securityToken, action, primaryKey) {
    var _this = this;
    _this.securityToken = securityToken;
    _this.action = action;
    _this.primaryKey = primaryKey;
}

/* Action inheritance */
PassThruAction.prototype = new Action();

/* Route pattern which is handled by this Action */
PassThruAction.route = 'auth/passThru/:securityToken/:action/:primaryKey';

/* Returns a route containing the specified parameters */
PassThruAction.getRoute = function getRoute(securityToken, action, primaryKey) {
    return 'auth/passThru/' + securityToken + '/' + action + '/' + primaryKey;
};

/* Returns the route which will display the current state of this Action */
PassThruAction.prototype.getRoute = function getRoute() {
    return PassThruAction.getRoute(
        this.securityToken,
        this.action,
        this.primaryKey
    );
};

/* Initializes the action in preparation for activation */
PassThruAction.prototype.initialize = function initialize() {
    var _this = this;
    _this.viewModel = new PassThruViewModel(
        _this.securityToken,
        _this.action,
        _this.primaryKey
    );
    util.removeQueryStringParameters();
    return _this.viewModel.initialize();
};

/* This action returns the user to the sign in screen */
PassThruAction.prototype.getBackLocation = function getBackLocation() {
    return {
        route: 'auth-login',
        parameters: []
    };
};

/* Export: PassThruAction type */
module.exports = PassThruAction;
