var Promise = require('promise');
var app = require('common/app');
var Action = require('common/actions/action');
var PasswordResetInviteViewModel = require('./viewmodel');

/* Action used to request password resets */
function PasswordResetInviteAction() {
}

/* Action inheritance */
PasswordResetInviteAction.prototype = new Action();

/* Single pane style control */
PasswordResetInviteAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
PasswordResetInviteAction.route = 'auth/passwordResetInvite';

/* Returns a route containing the specified parameters */
PasswordResetInviteAction.getRoute = function getRoute() {
    return PasswordResetInviteAction.route;
};

/* Returns the route which will display the current state of this Action */
PasswordResetInviteAction.prototype.getRoute = function getRoute() {
    return PasswordResetInviteAction.getRoute();
};

/* Login can only be activated if the user is not authenticated */
PasswordResetInviteAction.prototype.allowActivate = function allowActivate() {
    if (app.currentUser()) {
        return 'dashboard';
    }
    return true;
};

/* Initializes the action in preparation for activation */
PasswordResetInviteAction.prototype.initialize = function initialize() {
    this.viewModel = new PasswordResetInviteViewModel();
    return Promise.resolve(true);
};

/* Export: PasswordResetInviteAction type */
module.exports = PasswordResetInviteAction;
