var ko = require('knockout');
require('./style.less');

/* ViewModel to control the state of the system menu */
function MenuToggleViewModel(menu) {
    // wrap menu isOpen flag
    this.isOpen = ko.computed({
        read: function read() {
            return menu.isOpen();
        },
        write: function write(value) {
            menu.isOpen(value);
        }
    });

    // control placement in application header
    this.priority = 1;
}

/* View template */
MenuToggleViewModel.prototype.template = require('./view.html');

/* Toggle the state of the attached menu */
MenuToggleViewModel.prototype.toggle = function toggle() {
    this.isOpen(!this.isOpen());
};

/* Export: MenuToggleViewModel type */
module.exports = MenuToggleViewModel;
