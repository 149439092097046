var _ = require('lodash');
var ko = require('knockout');
var ViewModel = require('../viewmodel');

/* ViewModel to display content in the system header area */
function HeaderViewModel() {
    this._components = ko.observableArray();

    this.components = ko.computed(
        function computed() {
            return _.sortBy(this._components(), function sortBy(component) {
                return component.priority;
            });
        }.bind(this)
    );
}

// add ViewModel mixins
_.extend(HeaderViewModel.prototype, ViewModel);

/* View template */
HeaderViewModel.prototype.template = require('./view.html');

/* Add a component to the header */
HeaderViewModel.prototype.add = function add(component) {
    if (this._components.indexOf(component) >= -1) {
        this._components.push(component);
    }
};

/* Remove a component from the header */
HeaderViewModel.prototype.remove = function add(component) {
    var idx = this._components.indexOf(component);
    if (idx > -1) {
        this._components.splice(idx, 1);
    }
};

/* Export: HeaderViewModel type */
module.exports = HeaderViewModel;
