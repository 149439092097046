var ko = require('knockout');

/* Template source which uses a string template to render content */
function ViewTemplateSource(content) {
    this.content = content;
    this.data = {};
}

/* Reads and writes template content */
ViewTemplateSource.prototype.text = function text(value) {
    if (arguments.length === 0) {
        return this.content;
    }

    this.content = value;
    return undefined;
};

/* Reads and writes template data */
ViewTemplateSource.prototype.data = function data(key, value) {
    if (arguments.length === 1) {
        return this.data[key];
    }

    this.data[key] = value;
    return undefined;
};

/* Templating engine which can render Views */
function ViewTemplatingEngine() {
    // no constructor
}

// eslint-disable-next-line new-cap
ViewTemplatingEngine.prototype = new ko.nativeTemplateEngine();

/* Creates a ViewTemplateSource if a string-based template is requested */
ViewTemplatingEngine.prototype.makeTemplateSource = function makeTemplateSource(
    template
) {
    if (typeof template === 'string') {
        // eslint-disable-next-line new-cap
        return new ko.templateSources.viewTemplate(template);
    }

    return ko.nativeTemplateEngine.prototype.makeTemplateSource.call(
        this,
        template
    );
};

// Register with Knockout
ko.templateSources.viewTemplate = ViewTemplateSource;
ko.setTemplateEngine(new ViewTemplatingEngine());
