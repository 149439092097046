var _ = require('lodash');
var ko = require('knockout');
var $ = require('jquery');

var panels = [];
var timer;

require('nanoscroller');
require('nanoscroller/bin/css/nanoscroller.css');

function refreshNano() {
    timer = null;

    _.each(panels, function refreshPanel(panel) {
        $(panel).nanoScroller();
    });
}

function onNanoRemove(event) {
    var idx = panels.indexOf(event.target);
    if (idx > -1) {
        panels.splice(idx, 1);
    }
}

/* Creates a nano scrollbar on the target element */
ko.bindingHandlers.nano = {
    init: function initNano(element) {
        $(element)
            .nanoScroller()
            .bind('touchstart', function onTouchStart() {
                $('.nano-pane', $(element)).addClass('active');
            })
            .bind('touchend', function onTouchEnd() {
                $('.nano-pane', $(element)).removeClass('active');
            });

        panels.push(element);
        element.addEventListener('DOMNodeRemovedFromDocument', onNanoRemove);
    }
};

/* Refreshes all nano scrollbars in the document, causing them to re-calculate their size */
ko.bindingHandlers.nanoRefresh = {
    update: function updateNano(element, valueAccessor) {
        ko.utils.unwrapObservable(valueAccessor());

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(refreshNano, 50);
    }
};

/* Scrolls the target element into view in the nearest nano container */
ko.bindingHandlers.nanoScrollTo = {
    update: function updateNanoScroll(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        var $element;
        var $parent;
        var elementHeight;
        var elementOffset;
        var parentHeight;
        var parentOffset;
        var scrollTarget;

        if (!value) {
            return;
        }

        $element = $(element);
        $parent = $(element.closest('.nano-content'));
        elementHeight = $element.outerHeight();
        elementOffset = $element.offset().top;
        parentHeight = $parent.outerHeight();
        parentOffset = $parent.offset().top;

        if (elementOffset < parentOffset) {
            // off the top, scroll up
            scrollTarget = Math.max(
                0,
                $parent[0].scrollTop - parentOffset + elementOffset
            );
        } else if (
            elementOffset + elementHeight >
            parentHeight + parentOffset
        ) {
            // off the bottom, scroll down
            scrollTarget = Math.min(
                $parent[0].scrollHeight,
                $parent[0].scrollTop +
                    elementOffset -
                    parentOffset -
                    parentHeight +
                    elementHeight
            );
        }

        if (scrollTarget) {
            $parent.animate(
                {
                    scrollTop: scrollTarget
                },
                {
                    duration: 75
                }
            );
        }
    }
};
