var Promise = require('promise');
var app = require('common/app');
var Action = require('common/actions/action');
var LoginViewModel = require('./viewmodel');
var util = require('utility/util');

/* Action used to authenticate the user with the app */
function LoginAction() {
    this.companyId = util.getQueryStringParameterByName(
        'CompanyId',
        window.location.href
    );
}

/* Action inheritance */
LoginAction.prototype = new Action();

/* Single pane style control */
LoginAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
LoginAction.route = 'auth/login';

/* Returns a route containing the specified parameters */
LoginAction.getRoute = function getRoute() {
    return LoginAction.route;
};

/* Returns the route which will display the current state of this Action */
LoginAction.prototype.getRoute = function getRoute() {
    return LoginAction.getRoute();
};

/* Login can only be activated if the user is not authenticated */
LoginAction.prototype.allowActivate = function allowActivate() {
    if (app.currentUser()) {
        return 'dashboard';
    }
    return true;
};

/* Allow the app to exit from this action */
LoginAction.prototype.onBackRequested = function onBackRequested() {
    this.viewModel.confirmExit.notify();
};

/* Initializes the action in preparation for activation */
LoginAction.prototype.initialize = function initialize() {
    this.viewModel = new LoginViewModel(this.companyId);
    if (this.companyId) {
        util.removeQueryStringParameter('CompanyId');
    }
    return Promise.resolve(true);
};

/* Export: LoginAction type */
module.exports = LoginAction;
