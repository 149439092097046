module.exports = {
    Home: {
        'Menu.Home': 'Home'
    },
    'Home.Dashboard': {
        'Message.NoActionRequired':
            'There are no tasks which require your attention at this time.',
        'Button.Refresh': 'Refresh',
        'Message.InvalidResponse': 'The form response requested is not valid'
    }
};
