var _ = require('lodash');
var ko = require('knockout');
var app = require('common/app');
var ViewModel = require('common/viewmodel');
var passwordResetValidationService = require('../services/passwordResetValidation');
var passwordResetApplyService = require('../services/passwordResetApply');

require('./style.less');

function PasswordResetApplyViewModel(userId, token) {
    var _this = this;

    _this.userId = userId;
    _this.token = token;

    _this.isValid = ko.observable(true);
    _this.loading = ko.observable(true);
    _this.resetAttempted = ko.observable(false);
    _this.validationErrors = ko.observableArray([]);

    _this.emailAddress = ko.observable();
    _this.title = ko.observable();
    _this.password = ko.observable();
    _this.confirmPassword = ko.observable();
    _this.hasFocus = ko.observable(false);

    _this.resetOk = ko.computed(function getResetOk() {
        return _this.resetAttempted() && _this.validationErrors().length === 0;
    });

    _this.hasValidationErrors = ko.computed(function getHasValidationErrors() {
        return _this.validationErrors().length > 0;
    });
}

_.extend(PasswordResetApplyViewModel.prototype, ViewModel);

PasswordResetApplyViewModel.prototype.backToLogin = function backToLogin() {
    app.navigate('auth-login');
};

PasswordResetApplyViewModel.prototype.reset = function reset() {
    var _this = this;

    _this.loading(true);

    return passwordResetApplyService
        .invoke(_this.userId, _this.token, _this.password(), _this.confirmPassword())
        .then(function success(res) {
            _this.validationErrors(res.validationErrors);
            _this.resetAttempted(true);
            return undefined;
        })
        .catch(function catchServiceErr(err) {
            _this.isValid(false);
        })
        .finally(function cleanUp() {
            _this.loading(false);
            setTimeout(function giveFocus() { _this.hasFocus(true); }, 50);
            return undefined;
        });    
}

PasswordResetApplyViewModel.prototype.initialize = function initialize() {
    var _this = this;

    _this.loading(true);

    return passwordResetValidationService
        .invoke(_this.userId, _this.token)
        .then(function success(res) {
            _this.emailAddress(res.emailAddress);
            _this.title(res.title);
            return undefined;
        })
        .catch(function catchServiceErr(err) {
            _this.isValid(false);
        })
        .finally(function cleanUp() {
            _this.loading(false);
            setTimeout(function giveFocus() { _this.hasFocus(true); }, 50);
            return undefined;
        });
}

/* View template */
PasswordResetApplyViewModel.prototype.template = require('./view.html');

/* Export: LoginViewModel type */
module.exports = PasswordResetApplyViewModel;