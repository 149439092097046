var app = require('common/app');
var Action = require('common/actions/action');
var PasswordResetApplyViewModel = require('./viewmodel');
var util = require('utility/util');

/* Action used to apply for a password reset */
function PasswordResetApplyAction(userId, token) {
    var _this = this;
    _this.token = token;
    _this.userId = userId;
}

/* Action inheritance */
PasswordResetApplyAction.prototype = new Action();

/* Single pane style control */
PasswordResetApplyAction.prototype.isSinglePane = true;

/* Route pattern which is handled by this Action */
PasswordResetApplyAction.route = 'auth/passwordResetApply/:userId/:token';

/* Returns a route containing the specified parameters */
PasswordResetApplyAction.getRoute = function getRoute(userId, token) {
    return 'auth/passwordResetApply/' + userId + '/' + token;
};

/* Returns the route which will display the current state of this Action */
PasswordResetApplyAction.prototype.getRoute = function getRoute() {
    return PasswordResetApplyAction.getRoute(_this.userId, _this.token);
};

/* PasswordResetApplyAction can only be activated if the user is not authenticated */
PasswordResetApplyAction.prototype.allowActivate = function allowActivate() {
    if (app.currentUser()) {
        return 'dashboard';
    }
    return true;
};

/* Allow the app to exit from this action */
PasswordResetApplyAction.prototype.onBackRequested = function onBackRequested() {
    this.viewModel.confirmExit.notify();
};

/* Initializes the action in preparation for activation */
PasswordResetApplyAction.prototype.initialize = function initialize() {
    var _this = this;
    _this.viewModel = new PasswordResetApplyViewModel(_this.userId, _this.token);
    util.removeQueryStringParameters();
    return  _this.viewModel.initialize();;
};

/* Export: PasswordResetApplyAction type */
module.exports = PasswordResetApplyAction;
