var _ = require('lodash');
var Promise = require('promise');
var Backbone = require('backbone');
var moment = require('moment');
var packageManager = require('app/packageManager');
var Router = require('./router');
var Canvas = require('./canvas');
var Action = require('./actions/action');
var log = require('utility/log');

/* Central type to manage operation of the app */
function Application() {
    this.started = false;
    this._packages = [];
    this._staticCanvas = {};
    this.module = 'default';
}

// add event support
_.extend(Application.prototype, Backbone.Events);

/* Create the application components and bind to the canvas */
Application.prototype.create = function create(containerId, extensions) {
    // create view canvas and router
    this.container = new Canvas(containerId);
    this.router = new Router({
        canvas: this.container
    });

    // load extensions
    _.each(
        extensions,
        function each(value, key) {
            this[key] = value;
            this.renderStaticCanvas(key, value);
        }.bind(this)
    );
};

/* Starts the application */
Application.prototype.start = function start(route, parameters) {
    if (this.started) {
        throw new Error('Application has already started');
    }

    if (!this.container || !this.router) {
        throw new Error(
            'Application must be bound using .create() before starting'
        );
    }

    // start routing and history
    this.router.start(this.router.getRoute(route, parameters));

    // done
    this.started = true;
};

/* Navigate the application to the specified route */
Application.prototype.navigate = function navigate(route, parameters, options) {
    var optionsWithDefault;

    if (!this.started) {
        throw new Error('Application has not started');
    }

    optionsWithDefault = _.extend(
        {
            trigger: true
        },
        options
    );

    return this.router.navigate(
        this.router.getRoute(route, parameters),
        optionsWithDefault
    );
};

/* Sets the user currently authenticated by the application */
Application.prototype.changeUser = function changeUser(user, silent) {
    var tasks = [];
    this._currentUser = user;

    if (user) {
        if (user.cultureCode) {
            moment.locale(user.cultureCode);
        }

        if (user.enabledPackages) {
            _.each(
                user.enabledPackages,
                function each(p) {
                    tasks.push(this.requestPackage(p));
                }.bind(this)
            );
        }
    }

    return Promise.all(tasks).then(
        function then() {
            if (!silent) {
                this.trigger('change:user', user);
            }

            return null;
        }.bind(this)
    );
};

/* Returns the user currently authenticated by the application */
Application.prototype.currentUser = function currentUser() {
    return this._currentUser;
};

/* Handles back-button behavior */
Application.prototype.onBackButton = function onBackButton() {
    this.router.goBack();
};

/* Renders a viewmodel into a canvas which is not route-controlled */
Application.prototype.renderStaticCanvas = function renderStaticCanvas(
    containerId,
    viewModel
) {
    var canvas =
        this._staticCanvas[containerId] ||
        (this._staticCanvas[containerId] = new Canvas(containerId));

    canvas.activate({
        viewModel: viewModel,
        view: Action.prototype.view
    });
};

/* Loads a module package into the application */
Application.prototype.loadPackage = function loadPackage(p) {
    if (this._packages.indexOf(p) > -1) {
        log.write('Package {' + p.id + '} has already been loaded');
        return;
    }

    log.write('Loading package {' + p.id + '}');

    if (p.init) {
        log.write('Initializing package {' + p.id + '}');
        p.init(this);
    }

    _.forIn(
        p.actions,
        function forIn(value, key) {
            log.write(
                'Loading action {' + key + '} for package {' + p.id + '}'
            );
            this.router.register(p.id + '-' + key, value);
        }.bind(this)
    );

    this._packages.push(p);
    this.trigger('change:packages', this._packages);

    log.write('Package load complete');
};

/* Requests a remote package to be loaded into the application */
Application.prototype.requestPackage = function requestPackage(id) {
    var _this = this;

    if (!id) {
        throw new Error('Package must be requested with a valid id');
    }

    log.write('Requested remote package {' + id + '}');
    return new Promise(function promise(resolve) {
        packageManager.requestPackage(id, function onPackageRetrieved(p) {
            _this.loadPackage(p);

            resolve(true);
        });
    });
};

/* Returns a list of packages which are active for the current user */
Application.prototype.activePackages = function activePackages() {
    var user = this.currentUser();
    var enabledPackages = (user && user.enabledPackages) || [];

    return _.filter(this._packages, function filter(p) {
        return p.alwaysActive || enabledPackages.indexOf(p.id) > -1;
    });
};

/* Export: app singleton */
module.exports = new Application();

/* Import app extensions last to avoid circular dependencies */
require('../extensions/extensions');
